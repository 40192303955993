import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function ContractInsert() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Novo contrato</h2>
				<p>Escolher modelo de contrato para alterar</p>
			</div>
		</Fragment>
	)
}

export default ContractInsert
