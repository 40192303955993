import React, { Fragment } from 'react'
import Header from '../../../layout/Header'
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";

function DeliveryList() {
  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Entregas</h2>

				<div className="dash-chart-card" style={{width: '40%', height: '10%'}}>
					<h3>Status dos pedidos</h3>
					<Doughnut 
						data={{
							labels: ['devolução', 'esperando entregador', 'pendentes', 'em transito', 'concluídos'],
							datasets: [
								{
									label: '# of Votes',
									data: [12, 19, 3, 5, 2, 3],
									backgroundColor: [
										'rgba(255, 99, 132, 0.2)',
										'rgba(54, 162, 235, 0.2)',
										'rgba(255, 206, 86, 0.2)',
										'rgba(75, 192, 192, 0.2)',
										'rgba(153, 102, 255, 0.2)',
										'rgba(255, 159, 64, 0.2)',
									],
									borderColor: [
										'rgba(255, 99, 132, 1)',
										'rgba(54, 162, 235, 1)',
										'rgba(255, 206, 86, 1)',
										'rgba(75, 192, 192, 1)',
										'rgba(153, 102, 255, 1)',
										'rgba(255, 159, 64, 1)',
									],
									borderWidth: 1,
								},
							],
						}} 
						options = {{
							responsive: true,
						}}
					/>
				</div>
			</div>
		</Fragment>
  	)
}

export default DeliveryList
