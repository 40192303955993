import React, { Fragment } from 'react'

import Header from '../../../layout/Header'

function ReturnList() {
  return (
	<Fragment>
		<Header/>
		<div id="main">
			<h2>Devoluções</h2>
		</div>
	</Fragment>
  )
}

export default ReturnList
