import React, { Fragment } from 'react';
import Header from '../../../layout/Header';

const OrderList = () => {
	return(
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Pedidos</h2>
			</div>
		</Fragment>
	);
};

export default OrderList;
