import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function AddressList() {
	const [addresses, setAddress] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();


	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		var company_id = localStorage.getItem('selected_company');

		axios.get(`https://erp-api.55technology.com/v1/address/company/` + company_id, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setAddress(response.data.address);
			setLoading(false);
		  })
		.catch(error => console.error('Error fetching products:', error));
	}, [navigate]);

	const filteredProducts = addresses.filter(address =>
		address.street.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const totalItems = filteredProducts.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = filteredProducts.slice(startIndex, endIndex);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	return (
		<Fragment>
			<Header/>
			<div id="main">
		 		<section className="column">
				{
					(loading) 
					? <i className="fa-regular fa-sun rotating-star"></i>
					: <div className="column">
						<h2>Endereços</h2>
			 			
						<div className='table-option-top row'>
							<div>
								<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value="25">Mostrar 25 por página</option>
									<option value="50">Mostrar 50 por página</option>
									<option value="100">Mostrar 100 por página</option>
								</select>
							</div>
							<input 
								type="search" 
								placeholder="pesquisar" 
								value={searchTerm} 
								onChange={(e) => setSearchTerm(e.target.value)}
								style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
							/>
							<Link to='/address/create'>
								<button>
								<i className="fa-solid fa-user-plus"></i>
									Novo Endereço
								</button>
								</Link>
							</div>
							<table>
								<thead>
									<tr>
										<th>#</th>
										<th>Rua</th>
										<th>Número</th>
										<th>Bairro</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map(address => (
										<tr key={address.id}>
											<td>{address.id}</td>
											<td>{address.street}</td>
											<td>{address.number}</td>
											<td>{address.district}</td>
										</tr>
									))}
								</tbody>
							</table>
							<div className='table-option-bottom row'>
								<p>Mostrando ítens {startIndex + 1} a {endIndex > totalItems ? totalItems : endIndex}. </p>
								<p>Total: {totalItems} ítens</p>
								<p>Página {currentPage} de {totalPages}</p>
								<div>
									<button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
									<button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
								</div>
							</div>
						</div>
					}
				</section>
			</div>
		</Fragment>
	);
}

export default AddressList
