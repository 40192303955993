import React, {useState, useEffect} from 'react';
import axios from 'axios';

const UnitSelect = ({onSelect}) => {
		const [unitList, setUnitList] = useState([]);
		const [selectedValue, setSelectedValue] = useState('');

		useEffect(()=>{
			axios.get("https://erp-api.55technology.com/v1/unit")
				.then (response => {
						console.table(response.data.units);
						setUnitList(response.data.units);
				})
				.catch (error => {
						console.error("Erro na API", error);
				});
		},[]);

		const handleInputChange = (event) => {
			setSelectedValue(event.target.value);
			onSelect(event.target.value);
		};

		return (
			<div>
				<label htmlFor="unitList">Unidade:</label>

				<select onChange={handleInputChange} value={selectedValue}>
					{unitList.map(unit => (
						<option value={unit.id}>{unit.name}</option>
					))}
				</select>
			</div>
		);
}
export default UnitSelect;