import React, { Fragment } from 'react'

import Header from '../../../layout/Header'

function TargetList() {
  return (
	<Fragment>
		<Header/>
		<div id="main">
			<h2>Metas de venda</h2>
		</div>
  	</Fragment>
  )
}

export default TargetList
