import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function NpsOk() {
  return (
    <Fragment>
        <div id="main column">
            <h1 style={{textAlign: 'center', margin: 100}}>Avaliação cadastrada!</h1>
            <p style={{textAlign: 'center'}}>Muito obrigado por participar. :)</p>
        </div>
    </Fragment>
  )
}

export default NpsOk
