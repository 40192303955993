import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/src/createNumberMask';
import PermissionSelect from '../../../selects/PermissionSelect';

function EmployeeInsert() {
	const [employees, setEmployees] = useState([]);
	const [employeeEmail, setEmployeeEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(0);
	const [submitClicked, setSubmitClicked] = useState(false);

	const [amountInput, setAmountInput] = useState('');
	const [amount, setAmount] = useState('');
	const [selectedPermission, setSelectedPermission] = useState('');


	const navigate = useNavigate();

	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = (selected) => {
		setSelectedEmployee(selected);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const handleOverlayClick = (event) => {
		// Close the modal if the click is outside the modal content
		if (event.target.classList.contains('modal-overlay')) {
		  closeModal();
		}
	};

	const handlePermissionSelect = (value) => {
		setSelectedPermission(value);
	};

	const numberMask = createNumberMask({
		prefix: 'R$ ',
		suffix: '',
		includeThousandsSeparator: true,
		thousandsSeparatorSymbol: '.',
		allowDecimal: true,
		decimalSymbol: ',',
		decimalLimit: 2, // Adjust this based on your needs
		integerLimit: 11, // Adjust this based on your needs
	});

	const handleInputChange = (event) => {
		setAmountInput(event.target.value); // Mascara com R$ , . apenas pro input

		const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10);
		setAmount(numericValue);	// Int, pra enviar pro db
	};

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}
	}, [navigate]);

	const searchEmployee = () => {
		setLoading(true);

		var accessToken = localStorage.getItem('access_token');

		axios.post(`https://erp-api.55technology.com/v1/employee/search`,
			{ 
				"email": employeeEmail
			}, 
			{
				headers: { "token": accessToken } 
			}
		)
		.then(response => {
			setEmployees(response.data.employees);
			setLoading(false);
		  })
		.catch((error) => {
			alert(error);
			setLoading(false);	
		})
	}

	const sendInvite = async (e) => {
		e.preventDefault();
		setSubmitClicked(true);

		var accessToken = localStorage.getItem('access_token');
		var companyId = localStorage.getItem('selected_company');

		axios.post(`https://erp-api.55technology.com/v1/employee`,
			{ 
				"company_id": companyId,
				"user_id": selectedEmployee,
				"permission_id": selectedPermission,
				"amount": amount
			}, 
			{
				headers: { "token": accessToken } 
			}
		)
		.then(response => {
			setLoading(false);
			navigate('/employee');
		  })
		.catch((error) => {
			alert(error);
			setLoading(false);	
		})
	}

	return (
		<Fragment>
			<Header/>
			<div id="main">
		 		<section className="column">
					<div className="column">
						<h2>Procurar colaborador</h2>
						
						<div className='table-option-top row'>
							<input 
								type="search" 
								placeholder="pesquisar" 
								value={employeeEmail} 
								onChange={(e) => setEmployeeEmail(e.target.value)}
								style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
							/>
						</div>
						<button onClick={searchEmployee}>
							<i className="fa-solid fa-magnifying-glass"></i>
						</button>
						{
							(loading) 
							? 	<i className="fa-regular fa-sun rotating-star"></i>
							: 	<div className='row'>
									{employees.map(employee => (
										<div className="select-employee-card">
											<h2>{employee.name}</h2>
											<p>{employee.email}</p>
											<button onClick={() => openModal(employee.id)}>Convidar</button>
										</div>
									))}
								</div>
						}

						{isModalOpen && (
							<div className="modal-overlay" onClick={handleOverlayClick}>
								<div className="modal">
									<i className="fa-regular fa-circle-xmark close-button" onClick={closeModal}></i>
									<h2>Convidar funcionário.</h2>
									<form onSubmit={sendInvite}>
										<div>
											<label>Salário inicial:</label>
											<MaskedInput
												type='text'
												mask={numberMask}
												value={amountInput}
												onChange={handleInputChange}
												placeholder="R$10,00"
												required
											/>
										</div>

										<PermissionSelect onSelect={handlePermissionSelect}/>

										{
											submitClicked 
											? <i className="fa-regular fa-sun rotating-star"></i> 
											: <button
												type="submit"
												className="btn"
												disabled={submitClicked}
												> Convidar </button>
										}
									</form>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</Fragment>
	);
}

export default EmployeeInsert
