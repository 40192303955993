import React from 'react'

function PaymentSubscription() {
  return (
    <div className='row'>
        <div>
          <h2 style={{textAlign: 'center', margin:15}}>Teste</h2>
          <stripe-buy-button
            buy-button-id="buy_btn_1OHrJqCj8YYsyTrrYAVPV0Xe"
            publishable-key="pk_live_51MJhMTCj8YYsyTrrV4zIZXVS0x0MP9AlN8p1jmh2Oi4weJ7rjJ8bQkqYQJg0eQdDcdxT7JTXs6O9O06WrGXR6QSs00ZJgFg8Ni"
            >
          </stripe-buy-button>
        </div>

        <div>
          <h2 style={{textAlign: 'center', margin:15}}>Anual</h2>
          <stripe-buy-button
            buy-button-id="buy_btn_1OHqa4Cj8YYsyTrr4PSPgNBP"
            publishable-key="pk_live_51MJhMTCj8YYsyTrrV4zIZXVS0x0MP9AlN8p1jmh2Oi4weJ7rjJ8bQkqYQJg0eQdDcdxT7JTXs6O9O06WrGXR6QSs00ZJgFg8Ni"
          >
          </stripe-buy-button>
        </div>

        <div>
          <h2 style={{textAlign: 'center', margin:15}}>Semestral</h2>
          <stripe-buy-button
            buy-button-id="buy_btn_1OHrBtCj8YYsyTrrwCLxJoK4"
            publishable-key="pk_live_51MJhMTCj8YYsyTrrV4zIZXVS0x0MP9AlN8p1jmh2Oi4weJ7rjJ8bQkqYQJg0eQdDcdxT7JTXs6O9O06WrGXR6QSs00ZJgFg8Ni"
          >
          </stripe-buy-button>
        </div>

        <div>
          <h2 style={{textAlign: 'center', margin:15}}>Mensal</h2>
          <stripe-buy-button
            buy-button-id="buy_btn_1OHqSYCj8YYsyTrrJkzowXgb"
            publishable-key="pk_live_51MJhMTCj8YYsyTrrV4zIZXVS0x0MP9AlN8p1jmh2Oi4weJ7rjJ8bQkqYQJg0eQdDcdxT7JTXs6O9O06WrGXR6QSs00ZJgFg8Ni"
          >
          </stripe-buy-button>
        </div>
    </div>
  )
}

export default PaymentSubscription
