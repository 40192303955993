import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import axios from 'axios';
import { Link } from 'react-router-dom';

function RoleList() {
  	const [roles, setRoles] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		var selectedCompany = localStorage.getItem("selected_company");
		axios.get(`https://erp-api.55technology.com/v1/role/company/` + selectedCompany)
		.then(response => {
			setRoles(response.data.roles);
			setLoading(false);
		  })
		.catch(error => console.error('Error fetching products:', error));
	}, []);

	const filteredProducts = roles.filter(role =>
		role.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const totalItems = filteredProducts.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = filteredProducts.slice(startIndex, endIndex);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	return (
		<Fragment>
			<Header/>
			<div id="main">
		 		<section className="column">
				{
					(loading) 
					? <i className="fa-regular fa-sun rotating-star"></i>
					: <div className="column">
						<h2>Cargos</h2>
			 			<Link to='/role/create'>Novo Cargo</Link>
							<div>
								Mostrar 
								<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
								por página
								<input 
									type="text" 
									placeholder="pesquisar" 
									value={searchTerm} 
									onChange={(e) => setSearchTerm(e.target.value)}
									style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
									/>
							</div>
							<table>
								<thead>
									<tr>
										<th>#</th>
										<th>Nome</th>
										<th>Descrição</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map(role => (
										<tr key={role.id}>
											<td>{role.id}</td>
											<td>{role.name}</td>
											<td>{role.description}</td>
										</tr>
									))}
								</tbody>
							</table>
							<div>
								<p>Mostrando produtos {startIndex + 1} a {endIndex > totalItems ? totalItems : endIndex} , total: {totalItems}</p>
								<button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
								<button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
								<p>Página {currentPage} de {totalPages}</p>
							</div>
						</div>
					}
				</section>
			</div>
		</Fragment>
	);
}

export default RoleList
