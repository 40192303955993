import React, { Fragment } from 'react'
import Header from '../../../layout/Header'
import FunnelStep from '../../../layout/FunnelStep';

function Funnel() {
  return (
    <Fragment>
			<Header/>
			<div id="main">
				<section className="column">
          <FunnelStep color="blueviolet" title="Leads" amount="253" width="85" angle="5.5" top="50" link="/leads"/>
          <FunnelStep color="blueviolet" title="Atendimentos" amount="180" width="73" angle="5" top="55" link="/order"/>

          <FunnelStep color="green" title="Propostas" amount="50" width="62" angle="4.5" top="60" link="/proposal"/>
          <FunnelStep color="green" title="Contratos" amount="15" width="50" angle="4.5" top="65" link="/contract"/>

          <FunnelStep color="rgb(0, 146, 151)" title="Projetos" amount="12" width="40" angle="3.5" top="70" link="/project" />
          <FunnelStep color="rgb(0, 146, 151)" title="Produção" amount="5" width="32" angle="3" top="72" link="/project"/>
          <FunnelStep color="rgb(0, 146, 151)" title="Entrega" amount="2" width="25" angle="2.5" top="75" link="/delivery"/>

          <FunnelStep color="blue" title="Remarketing" amount="10" width="19" angle="2.5" top="70" link="/client" />
        </section>
      </div>
    </Fragment>
  )
}

export default Funnel
