import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function Dre() {
  return (
	<Fragment>
		<Header/>
		<div id="main">
			<h2>DRE</h2>
		</div>
	</Fragment>
  )
}

export default Dre
