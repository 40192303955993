import React, { Fragment } from 'react'
import Header from '../../../layout/Header';

function FoodOrderList() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Comandas</h2>
			</div>
		</Fragment>
	)
}

export default FoodOrderList