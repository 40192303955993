import React, { Fragment } from 'react'
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Header from '../../layout/Header';
import { Link } from 'react-router-dom';

function Dash() {
	const DATA_COUNT = 12;
	const labels = [];
	for (let i = 0; i < DATA_COUNT; ++i) {
		labels.push(i.toString());
	}

	const datapoints = [150, 35, 40, 65, 65, 120, NaN, 150, 120, 125, 105, 110, 170];

	return (
		<Fragment>
			<Header/>

            <div id="main">
				<div className="row">
					<Link to='/wallet/deposit' className="dash-card column">
						<h2>R$100.000,00</h2>
						<p>Recebidos hoje</p>
					</Link>

					<Link to='/wallet' className="dash-card column">
						<h2>R$500.000,00</h2>
						<p>Recebidos este mês</p>
					</Link>

					<Link to='/wallet/takeout' className="dash-card column">
						<h2>0</h2>
						<p>Contas a pagar</p>
					</Link>

					<Link to='/delivery' className="dash-card column">
						<h2>50</h2>
						<p>Entregas agendadas</p>
					</Link>
				</div>

				<div className="row">
					<div className="dash-chart-card">
							<h3>Recebidos do mês</h3>
							<div className="row">
								<div className="row" style={{width: '50%'}}>
									<Doughnut 
										data={{
											labels: ['Concluídos', 'Pendentes', 'Em processamento'],
											datasets: [
												{
													label: '# of Votes',
													data: [12, 19, 3],
													backgroundColor: [
														'rgba(54, 162, 235, 0.2)',
														'rgba(255, 99, 132, 0.2)',
														'rgba(255, 206, 86, 0.2)',
													],
													borderColor: [
														'rgba(54, 162, 235, 1)',
														'rgba(255, 99, 132, 1)',
														'rgba(255, 206, 86, 1)',
													],
													borderWidth: 1,
												},
											],
										}} 
										options = {{
											legend: {
												display: false
											},
											tooltips: {
												callbacks: {
												label: function(tooltipItem) {
														return tooltipItem.yLabel;
												}
												}
											},
											responsive: true,
										}}
									/>
								</div>
								<div>
									<p>R$10.000 Total</p>
									<p>R$10.000 Concluídos</p>
									<p>R$10.000 Pendentes</p>
								</div>
							</div>
						</div>

						<div className="dash-chart-card">
							<h3>Pagamentos do mês</h3>
							<div className="row">
								<div className="row" style={{width: '50%'}}>
									<Doughnut 
										data={{
											labels: ['Concluídos', 'Pendentes', 'Em processamento'],
											datasets: [
												{
													label: '# of Votes',
													data: [12, 19, 3],
													backgroundColor: [
														'rgba(54, 162, 235, 0.2)',
														'rgba(255, 99, 132, 0.2)',
														'rgba(255, 206, 86, 0.2)',
													],
													borderColor: [
														'rgba(54, 162, 235, 1)',
														'rgba(255, 99, 132, 1)',
														'rgba(255, 206, 86, 1)',
													],
													borderWidth: 1,
												},
											],
										}} 
										options = {{
											legend: {
												display: false
											},
											tooltips: {
												callbacks: {
												label: function(tooltipItem) {
														return tooltipItem.yLabel;
												}
												}
											},
											responsive: true,
										}}
									/>
								</div>
								<div>
									<p>R$10.000 Total</p>
									<p>R$10.000 Concluídos</p>
									<p>R$10.000 Pendentes</p>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="dash-chart-card">
							<h3>Metas de venda</h3>
							<Bar options={{
								responsive: true,
								plugins: {
								legend: {
									position: 'top',
								},
								title: {
									display: false,
									// text: 'Chart.js Bar Chart',
								},
								},
							}}
							data={{
									labels: ['January', 'February', 'March', 'April', 'May', 'June'],
									datasets: [
									  {
										label: 'Meta',
										data: [100, 100, 100, 100, 100, 100],
										backgroundColor: 'rgba(255, 99, 132, 0.5)',
									  },
									  {
										label: 'Venda',
										data: [120, 125, 95, 115, 150, 120],
										backgroundColor: 'rgba(53, 162, 235, 0.5)',
									  },
									],
								
							}} />
						</div>

						<div className="dash-chart-card">
							<h3>Gastos por setor</h3>
							<Bar options={{
								responsive: true,
								plugins: {
									legend: {
										position: 'top',
									},
									title: {
										display: false,
										// text: 'Chart.js Bar Chart',
									},
								},
							}}
							data={{
								labels: ['January', 'February', 'March', 'April', 'May', 'June'],
								datasets: [
									{
									label: 'Teto',
									data: [100, 100, 100, 100, 100, 100],
									backgroundColor: 'rgba(255, 99, 132, 0.5)',
									},
									{
									label: 'Gasto',
									data: [120, 125, 95, 115, 150, 120],
									backgroundColor: 'rgba(53, 162, 235, 0.5)',
									},
								],
							}} />
						</div>
					</div>


					<div className="row">
						<div className="dash-chart-card" style={{width: '95%', height: '20%'}}>
							<h3> Faturamento semestral</h3>
							<Line
								data={{
									labels: labels,
									datasets: [
										{
											label: 'Vendas semestrais',
											data: datapoints,
											fill: true,
											tension: 0.35,
											borderDash: [5],
											borderWidth: 2,
											pointRadius: 5,
											pointStyle: 'star',
											borderColor: 'rgba(20, 50, 250, 0.5)',
											pointBorderColor: 'rgba(20, 30, 250, 1)',
											pointBackgroundColor: 'rgba(20, 30, 250, 0.5)',
											backgroundColor: (context) => {
												const ctx = context.chart.ctx;
												const gradient = ctx.createLinearGradient(0, 0, 0, 500);
												gradient.addColorStop(0, "rgba(20, 50, 255, 1)");
												gradient.addColorStop(0.3, "rgba(20, 50, 255, 0.2");
												gradient.addColorStop(1, "rgba(20, 50, 255, 0");
												return gradient;
											},
										}, 
									]
								}}
							/>
						</div>
					</div>

					<div className="row">
						<div className="dash-chart-card" style={{width: '95%', height: '20%'}}>
							<h3>Produtos mais vendidos</h3>
							<Bar options={{
								responsive: true,
								plugins: {
								legend: {
									position: 'top',
								},
								title: {
									display: false,
									// text: 'Chart.js Bar Chart',
								},
								},
							}}
							data={{
								labels: ['Produto 1', 'Produto 2', 'Produto 3', 'Produto 4', 'Produto 5', 'Produto 6', 'Produto 7', 'Produto 8', 'Produto 9', 'Produto 10'],
								datasets: [
									{
									label: 'Venda',
									data: [125, 120, 115, 110, 105, 100, 90, 85, 70, 50],
									backgroundColor: (context) => {
										const ctx = context.chart.ctx;
										const gradient = ctx.createLinearGradient(0, 0, 0, 500);
										gradient.addColorStop(0, "rgba(20, 50, 255, 1)");
										gradient.addColorStop(0.4, "rgba(20, 50, 255, 0.4");
										gradient.addColorStop(1, "rgba(250, 50, 25, 0.15");
										return gradient;
									},
									},
								],
							}} />
						</div>
					</div>


					<div className="row">
						<div className="dash-chart-card">
							<h3> Cidades com mais clientes</h3>
							<table>
								<tr>
									<th>Cidade</th>
									<th>Estado</th>
									<th>Clientes</th>
									<th>Vendas</th>
								</tr>
								<tr>
									<td>São Paulo</td>
									<td>SP</td>
									<td>500</td>
									<td>1000</td>
								</tr>
								<tr>
									<td>Rio de Janeiro</td>
									<td>RJ</td>
									<td>400</td>
									<td>800</td>
								</tr>
								<tr>
									<td>Belo Horizonte</td>
									<td>MG</td>
									<td>300</td>
									<td>600</td>
								</tr>
								<tr>
									<td>Porto Alegre</td>
									<td>RS</td>
									<td>250</td>
									<td>500</td>
								</tr>
								<tr>
									<td>Curitiba</td>
									<td>PR</td>
									<td>200</td>
									<td>400</td>
								</tr>
								<tr>
									<td>Salvador</td>
									<td>BA</td>
									<td>150</td>
									<td>300</td>
								</tr>
								<tr>
									<td>Recife</td>
									<td>PE</td>
									<td>100</td>
									<td>200</td>
								</tr>
								<tr>
									<td>Fortaleza</td>
									<td>CE</td>
									<td>90</td>
									<td>180</td>
								</tr>
								<tr>
									<td>Brasília</td>
									<td>DF</td>
									<td>80</td>
									<td>160</td>
								</tr>
								<tr>
									<td>Manaus</td>
									<td>AM</td>
									<td>70</td>
									<td>140</td>
								</tr>
							</table>
						</div>

						<div className="dash-chart-card">
							<h3>Produtos mais vendidos</h3>
							<table>
								<tr>
									<th>#</th>
									<th>Produto</th>
									<th>Vendas</th>
								</tr>
								<tr>
									<td>55</td>
									<td>SP</td>
									<td>1000</td>
								</tr>
								<tr>
									<td>55</td>
									<td>RJ</td>
									<td>800</td>
								</tr>
								<tr>
									<td>55</td>
									<td>MG</td>
									<td>600</td>
								</tr>
								<tr>
									<td>55</td>
									<td>RS</td>
									<td>500</td>
								</tr>
								<tr>
									<td>55</td>
									<td>PR</td>
									<td>400</td>
								</tr>
								<tr>
									<td>55</td>
									<td>BA</td>
									<td>300</td>
								</tr>
								<tr>
									<td>55</td>
									<td>PE</td>
									<td>200</td>
								</tr>
								<tr>
									<td>55</td>
									<td>CE</td>
									<td>180</td>
								</tr>
								<tr>
									<td>55</td>
									<td>DF</td>
									<td>160</td>
								</tr>
								<tr>
									<td>55</td>
									<td>AM</td>
									<td>140</td>
								</tr>
							</table>
						</div>
					</div>
            </div>
		</Fragment>
	)
}

export default Dash