import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function MailInsert() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Nova cotação dos correios</h2>
			</div>
		</Fragment>
	)
}

export default MailInsert
