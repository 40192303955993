import React from 'react'
import { useNavigate } from 'react-router-dom'

function FunnelStep(props) {
	const navigate = useNavigate();

    return (
        <div className="funnel-step" onClick={() => navigate(props.link)}>
            <div className="funnel-step-left" style={{borderColor: props.color+" transparent", borderTopWidth: props.top+"px", borderLeftWidth:props.angle+"vw"}}></div>
            <div className="funnel-step-center" style={{width: props.width+"vw", height: props.top+"px", backgroundColor: props.color}}>
                <h3>{props.title}</h3>
                <p>{props.amount}</p>
            </div>
            <div className="funnel-step-right" style={{borderColor: props.color+" transparent", borderTopWidth: props.top+"px", borderRightWidth:props.angle+"vw"}}></div>
        </div>
    )
}

export default FunnelStep
