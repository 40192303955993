import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserCreate = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [registerClicked, setRegisterClicked] = useState(false);

  // Form data
  const [userName, setUserName] = useState('');
	const [userCpf, setUserCpf] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userPhone, setUserPhone] = useState('');
	const [userNotificate, setUserNotificate] = useState('');
	const [userBirthdate, setUserBirthdate] = useState('');
	const [userRg, setUserRG] = useState('');
	const [userPix, setUserPix] = useState('');
	const [userPass, setUserPass] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [termsAccepted, setTermsAccepted] = useState('');

  const navigate = useNavigate();

	const handleInsert = async (e) => {
		e.preventDefault();

    if (userPass !== confirmPass) {
      alert("Ops, as senhas estão diferentes.")
      setRegisterClicked(false);
      return
    }

    // bloqueia o botão e renderiza a animação
    setRegisterClicked(true);

		const headersList = {
			"Accept": "*/*",
		}

		const reqOptions = {
			// url: "http://erp-api.55technology.com/v1/user",
      url: "https://erp-api.55technology.com/v1/user",
			method: "POST",
			headers: headersList,
			data: {
        "stripe_id": "HIJ123",
        "name": userName,
        "cpf": userCpf,
        "rg": userRg,
        "birthdate": userBirthdate,
        "email": userEmail,
        "phone": userPhone,
        "pix": userPix,
        "country_id": 1,
        "city_id": 160,
        "gender_id": 1,
        "password": userPass,
        "permission_id": 7
      }
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);

			// Navigate to /dash route
			navigate("/login");
		} catch (error) {
      setRegisterClicked(false);

			alert(error)
			console.error('Error:', error.data);
		}
	};

  const nextPrev = (n) => {
    setCurrentTab(currentTab + n);
  };

  // When the user scrolls the page, execute myFunction 
  window.onscroll = () => progressBarEffect();

  function progressBarEffect() {
    if (!registerClicked){
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      document.getElementById("myBar").style.width = scrolled + "%";
    }
  }

  return (
    <div>
      <form id="regForm" action="/action_page.php">
        <h1>Seja bem vindo!</h1>

        <div className={`tab ${currentTab === 0 ? 'active' : ''}`}>
          <p>
            Seu nome:
            <input 
								type="text" 
								placeholder="Nome completo"
								value={userName} 
								onChange={(e) => setUserName(e.target.value)} 
								required 
						/>
          </p>
          <p>
            Seu CPF:
            <input 
								type="text"
                placeholder="000.000.000.00"
								value={userCpf} 
								onChange={(e) => setUserCpf(e.target.value)} 
								required 
						/>
          </p>
        </div>

        <div className={`tab ${currentTab === 1 ? 'active' : 'hidden'}`}>
          <p>
            E-mail:
            <input
								type="email" 
                placeholder="email@email.com"
								value={userEmail} 
								onChange={(e) => setUserEmail(e.target.value)} 
								required 
						/>
          </p>
          <p>
            Celular:
            <input 
								type="text" 
                placeholder="(00) 0 0000-0000"
								value={userPhone} 
								onChange={(e) => setUserPhone(e.target.value)} 
								required 
						/>
          </p>
          <p>
            <h3>Gostaria de receber novidades e promoções via whatsapp?</h3>
            <label className="switch">
              <input 
								type="checkbox" 
								value={userNotificate} 
								onChange={(e) => setUserNotificate(e.target.value)} 
								required 
						  />
              <span className="slider round"></span>
            </label>
          </p>          
        </div>
        <div className={`tab ${currentTab === 2 ? 'active' : 'hidden'}`}>
          <p>
            Nascimento:
            <input 
								type="date" 
								value={userBirthdate} 
								onChange={(e) => setUserBirthdate(e.target.value)} 
								required 
						/>
          </p>
          <p>
            RG:
            <input 
								type="text"
                placeholder="1.000.000-00"
								value={userRg} 
								onChange={(e) => setUserRG(e.target.value)} 
								required 
						/>
          </p>
          <p>
            Chave pix (usada para receber através de links e planos de pagamentos):
            <input 
								type="text" 
                placeholder="yyyy"
								value={userPix} 
								onChange={(e) => setUserPix(e.target.value)} 
								required 
						/>
          </p>
          <p>
            País
            <select name="" id="">
              <option value="">Selecione</option>
              <option value="">teste</option>
              <option value="">teste</option>
            </select>
          </p>
          <p>
            Estado
            <select name="" id="">
              <option value="">Selecione</option>
              <option value="">teste</option>
              <option value="">teste</option>
            </select>
          </p>
          <p>
            Cidade
            <select name="" id="">
              <option value="">Selecione</option>
              <option value="">teste</option>
              <option value="">teste</option>
            </select>
          </p>
        </div>
        <div className={`tab ${currentTab === 3 ? 'active' : 'hidden'}`}>
          <p>
            Para finalizar, adicione sua senha de acesso.
            <input 
								type="password" 
                placeholder="Senha"
								value={userPass} 
								onChange={(e) => setUserPass(e.target.value)} 
								required 
						/>
          </p>
          <p>
            Por favor, confirme a senha.
            <input 
								type="password" 
                placeholder="Confirme a senha"
								value={confirmPass} 
								onChange={(e) => setConfirmPass(e.target.value)} 
								required 
						/>
          </p>
        </div>

        <div className={`tab ${currentTab === 4 ? 'active' : 'hidden'}`}>
         
         <h2>Termos de uso e privacidade</h2>
          <div className="scroll-header">
            <div className="progress-container">
              <div className="progress-bar" id="myBar"></div>
            </div>  
          </div>

          <div className="content">
            <h3>Lalalalalalala</h3>
            <p>Lalalala</p>
            <p>Oioi</p>
            <p>Lalalala</p>
            <h3>Termos de uso e privacidade</h3>
            <p>It also <b>works when you scroll back up</b>.</p>
            <p>It is even <b>responsive</b>! Resize the browser window to see the effect.</p>
            <p>Some text to enable scrolling.. <b> Lorem ipsum dolor sit amet, </b> illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
          </div>

          <p>
            <h3>Declaro que li e estou de acordo com os temos de uso e privacidade apresentados:</h3>
            <label className="switch">
              <input type="checkbox"/>
              <input 
								type="checkbox" 
								value={termsAccepted} 
								onChange={(e) => setTermsAccepted(e.target.value)} 
								required 
						  />
              <span className="slider round"></span>
            </label>
          </p>   
        </div>
        <div className='button-group' style={{ overflow: 'auto' }}>
          {currentTab < 4 ? (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                id="prevBtn"
                onClick={() => nextPrev(-1)}
                disabled={currentTab === 0}
              >
                Voltar
              </button>

              <button type="button" id="nextBtn" onClick={() => nextPrev(1)}>
                Continuar
              </button>
            </div>
          ) : (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                id="prevBtn"
                onClick={() => nextPrev(-1)}
                disabled={currentTab === 0}
                > Voltar </button>

              {
                registerClicked 
                ? <i className="fa-regular fa-sun rotating-star"></i> 
                : <button
                    type="button"
                    id="registerBtn"
                    onClick={handleInsert}
                    disabled={registerClicked}
                  > Registrar </button>
              }
            </div>
          )}
        </div>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          {[...Array(5)].map((_, index) => (
            <span key={index} className={`step ${currentTab === index ? 'active' : ''}`}></span>
          ))}
        </div>
      </form>
    </div>
  );
};

export default UserCreate;
