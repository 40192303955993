import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function ReturnInsert() {
  return (
	<Fragment>
		<Header/>
		<div id="main">
			<h2>Nova devolução</h2>
		</div>
  	</Fragment>
  )
}

export default ReturnInsert
