import React, { Fragment, useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import Header from '../../../layout/Header'

function TimerInsert() {

	const [map, setMap] = useState(null);
	const [userLocation, setUserLocation] = useState(null);
	const [imageData, setImageData] = useState(null);
	const [videoStream, setVideoStream] = useState(null);
	const [streaming, setStreaming] = useState(null);

	useEffect(() => {
		if (map) {
			map.locate({ enableHighAccuracy: true });

			map.on('locationfound', (e) => {
				setUserLocation(e.latlng);
			});

			map.on('locationerror', () => {
				alert('Failed to get your location. Please enable location services.');
			});
		}
	}, [map]);

	const handleRegisterLocation = () => {
		// Open camera to capture a selfie
		navigator.mediaDevices
		.getUserMedia({ video: true })
		.then((stream) => {

			setStreaming(stream);

			const video = document.createElement('video');
			document.body.appendChild(video);
			video.srcObject = stream;
			video.play();

			setVideoStream(video)

			videoStream.play();

		})
		.catch((error) => {
			console.error('Error accessing camera:', error);
		});
	};

	const handleImageCapture = () => {
		// Capture image after a delay
				const canvas = document.createElement('canvas');
				
				canvas.width = videoStream.videoWidth;
				canvas.height = videoStream.videoHeight;
				
				const context = canvas.getContext('2d');

				context.drawImage(videoStream, 0, 0, canvas.width, canvas.height);
				
				const capturedImageData = canvas.toDataURL('image/jpeg');
				setImageData(capturedImageData);

				// Save image data to localStorage
				localStorage.setItem('capturedImageData', capturedImageData);

				// Clean up
				streaming.getVideoTracks()[0].stop();
				document.body.removeChild(videoStream);
	}

	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Registrar ponto</h2>
				<div>
					<MapContainer
						center={[0, 0]}
						zoom={13}
						style={{ height: '400px', width: '100%' }}
						whenCreated={setMap}
					>

						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>

						{userLocation && (
							<Marker position={userLocation}>
								<Popup>You are here!</Popup>
							</Marker>
						)}
					</MapContainer>

					<button onClick={handleRegisterLocation}>Registrar Ponto</button>

					<button onClick={handleImageCapture}>Capturar imagem</button>

					{imageData && (
						<div>
							<h3>Captured Image:</h3>
							<img src={imageData} alt="Captured" style={{ maxWidth: '100%' }} />
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default TimerInsert