import { useParams } from 'react-router-dom';

function CompanyUpdate() {
  const { id } = useParams();

  return (
    <div>
      <h2>Update Company {id}</h2>
    </div>
  );
}

export default CompanyUpdate;