import React, { Fragment, useEffect, useState } from 'react'

import Header from '../../../layout/Header'
import { Link, useNavigate } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/src/createNumberMask';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import ProductCategorySelect from '../../../selects/ProductCategorySelect';

const ProductInsert = () => {
	const [name, setName] = useState('');
	const [amountInput, setAmountInput] = useState('');
	const [amount, setAmount] = useState('');
	const [description, setDescription] = useState('');

	const [productCategorySelected, setProductCategorySelect] = useState('');
	
	const [submitClicked, setSubmitClicked] = useState(false);
	const [companyId, setCompanyId] = useState('');
	const [token, setToken] = useState('');
	const navigate = useNavigate();

	const numberMask = createNumberMask({
		prefix: 'R$ ',
		suffix: '',
		includeThousandsSeparator: true,
		thousandsSeparatorSymbol: '.',
		allowDecimal: true,
		decimalSymbol: ',',
		decimalLimit: 2, // Adjust this based on your needs
		integerLimit: 11, // Adjust thi based on your needs
	});

	const handleProductCategorySelect = (value) => {
		setProductCategorySelect(value);
	};

	const handleInputChange = (event) => {
		setAmountInput(event.target.value); // Mascara com R$ , . apenas pro input

		const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10);
		setAmount(numericValue);	// Int, pra enviar pro db
	};

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);


	const handleInsert = async (e) => {
		e.preventDefault();
        setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/product",
			method: "POST",
			headers: headersList,
			data: {
				"name": name,
				"company_id": companyId,
				"category_id": productCategorySelected,
				"amount": amount,
				"description": description,
			}
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/product", { replace: true });
		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Novo produto</h2>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<ProductCategorySelect onSelect={handleProductCategorySelect}/>
							<Link className='btn' to='/product/category/create'>+</Link>
						</div>
						<div>
							<label>Descrição:</label>
							<input 
								type="text" 
								value={description} 
								onChange={(e) => setDescription(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Valor:</label>
							<MaskedInput
								type='text'
								mask={numberMask}
								value={amountInput}
								onChange={handleInputChange}
								placeholder="R$10,00"
							/>
						</div>

						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar </button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
};

export default ProductInsert;
