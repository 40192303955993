import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ClientSelect from '../../../selects/ClientSelect';

function ProjectInsert() {
	const [companyId, setCompanyId] = useState('');
	const [selectedClient, setSelectedClient] = useState('');
	const [name, setName] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [amount, setAmount] = useState('');
	const [description, setDescription] = useState('');

    const [token, setToken] = useState('');
	const [submitClicked, setSubmitClicked] = useState(false);


	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);

	const handleClientSelect = (value) => {
		setSelectedClient(value);
	};

	const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/project",
			method: "POST",
			headers: headersList,
			data: {
				"company_id": companyId,
				"client_id": selectedClient,
				"name": name,
				"start_date": startDate,
				"end_date": endDate,
				"amount": amount,
				"description": description,
			}
		};

		try {
			const response = await axios.request(reqOptions);

			console.log('create successful:', response.data);

			setSubmitClicked(false);

			navigate("/project", { replace: true });

		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Novo projeto</h2>
						<div>
							<ClientSelect onSelect={handleClientSelect}/>
							{/* <p>Selecionado: {selectedClient}</p>  */}
						</div>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>Início:</label>
							<input 
								type="date" 
								value={startDate} 
								onChange={(e) => setStartDate(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>Final:</label>
							<input 
								type="date" 
								value={endDate} 
								onChange={(e) => setEndDate(e.target.value)} 
								required 
							/>
						</div>
						
						<div>
							<label>Valor:</label>
							<input 
								type="number" 
								value={amount} 
								onChange={(e) => setAmount(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>Descrição:</label>
						</div>
						<div>
							<textarea name="" id="" cols="30" rows="10" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
						</div>
						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar 
							</button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default ProjectInsert
