import React, {useState, useEffect} from 'react';
import axios from 'axios';

const PermissionSelect = ({onSelect}) => {
		const [permissionList, setPermissionList] = useState([]);
		const [selectedValue, setSelectedValue] = useState('');

		useEffect(()=>{
			axios.get("https://erp-api.55technology.com/v1/permission")
			.then (response => {
					console.table(response.data.permissions);
					setPermissionList(response.data.permissions);
			})
			.catch (error => {
					console.error("Erro na API", error);
			});
		},[]);

		const handleInputChange = (event) => {
			setSelectedValue(event.target.value);
			onSelect(event.target.value);
		};

		return (
			<div>
				<label for="">Permissão</label>
				<select value={selectedValue} onChange={handleInputChange}>
					{permissionList.map(permission => (
						<option value={permission.id}>{permission.name}</option>
					))}
				</select>
			</div>
		);
}
export default PermissionSelect;