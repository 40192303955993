import React from 'react'

function ProposalList() {
  return (
    <div>
      <h3>Lista propostas</h3>
    </div>
  )
}

export default ProposalList
