import React, {useState, useEffect} from 'react';
import axios from 'axios';

const PaymentMethodsSelect = ({onSelect}) => {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(()=>{
		axios.get("https://erp-api.55technology.com/v1/payment_type")
		.then (response => {
				setPaymentMethods(response.data.payment_types);
		})
		.catch (error => {
				console.error("Erro na API", error);
		});
	},[]);

	const handleInputChange = (event) => {
			setSelectedValue(event.target.value);
			onSelect(event.target.value);
		};

	return (
		<div>
			<label htmlFor="paymentMethod">Forma de pagamento</label>
			<select onChange={handleInputChange} value={selectedValue}>
				{paymentMethods.map(method => (
					<option value={method.id}> {method.type}</option>
				))}
			</select>    
		</div>
	);
}
export default PaymentMethodsSelect;