import React, { Fragment, useState } from 'react';
import Header from '../../layout/Header';

const Calendar = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const swap = () => {
    setIsFlipped(!isFlipped);
  }

  return (
    <Fragment>
        <Header/>

        <div id="main">
            <div className="container">
                <div className={`calendar ${isFlipped ? 'flip' : ''}`}>
                    <div className="front">
                        <div className="current-date">
                            <h1>Quinta 09/11</h1>
                            <h1>Novembro 2023</h1> 
                        </div>

                        <div className="current-month">
                            <ul className="week-days">
                                <li>DOM</li>
                                <li>SEG</li>
                                <li>TER</li>
                                <li>QUA</li>
                                <li>QUI</li>
                                <li>SEX</li>
                                <li>SAB</li>
                            </ul>

                            <div className="weeks">
                                <div className="first">
                                    <span className="last-month" onClick={swap}>28</span>
                                    <span className="last-month" onClick={swap}>29</span>
                                    <span className="last-month" onClick={swap}>30</span>
                                    <span className="last-month" onClick={swap}>31</span>
                                    <span onClick={swap}>01</span>
                                    <span onClick={swap}>02</span>
                                    <span onClick={swap}>03</span>
                                </div>

                                <div className="second">
                                    <span onClick={swap}>04</span>
                                    <span onClick={swap}>05</span>
                                    <span className="event" onClick={swap}>06</span>
                                    <span onClick={swap}>07</span>
                                    <span onClick={swap}>08</span>
                                    <span onClick={swap}>09</span>
                                    <span onClick={swap}>10</span>
                                </div>

                                <div className="third">
                                    <span onClick={swap}>11</span>
                                    <span onClick={swap}>12</span>
                                    <span onClick={swap}>13</span>
                                    <span onClick={swap}>14</span>
                                    <span onClick={swap}>15</span>
                                    <span onClick={swap}>16</span>
                                    <span className="event"  onClick={swap}>17</span>
                                </div>

                                <div className="fourth">
                                    <span onClick={swap}>18</span>
                                    <span onClick={swap}>19</span>
                                    <span onClick={swap}>20</span>
                                    <span onClick={swap}>21</span>
                                    <span onClick={swap}>22</span>
                                    <span onClick={swap}>23</span>
                                    <span onClick={swap}>24</span>
                                </div>

                                <div className="fift">
                                    <span onClick={swap}>25</span>
                                    <span className="event" onClick={swap}>26</span>
                                    <span onClick={swap}>27</span>
                                    <span className="event" onClick={swap}>28</span>
                                    <span onClick={swap}>29</span>
                                    <span onClick={swap}>30</span>
                                    <span className="last-month" onClick={swap}>01</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="back">
                        <input placeholder="Qual o evento?" />
                        <p><input type="date" name="" id=""/></p>
                        <p><input type="time" name="" id=""/></p>
                        <p></p>
                        <input placeholder="Endereço"/>
                        <input placeholder="Lembrete"/>

                        <div className="actions">
                            <button className="dismiss" onClick={swap}>
                                Cancelar <i className="ion-android-close"></i>
                            </button>
                            <button className="save" onClick={swap}>
                                Salvar <i className="ion-checkmark"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>    
  );
}

export default Calendar;
