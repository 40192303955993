import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../layout/Header'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function Profile() {
    const [companies, setCompanies] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		var accessToken = localStorage.getItem('access_token');
		
		axios.get(`https://erp-api.55technology.com/v1/company/owner`, {
			headers: { "token": accessToken }
		})

		.then(response => {
			setCompanies(response.data.companies);
			setLoading(false);
		})
		.catch(error => {
			console.error('Error fetching companies:', error);
			navigate("/login");
		});
	}, [navigate]);

	const filteredProducts = companies.filter(company =>
		company.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const totalItems = filteredProducts.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = filteredProducts.slice(startIndex, endIndex);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value)); 
		setCurrentPage(1);
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	return (
		<Fragment>
			<Header/>
			<div id="main">
			<section className="column">
					{
						(loading) 
						? <i className="fa-regular fa-sun rotating-star"></i>
						: <div className="column">
							<h2>Minhas empresas</h2>
							<Link to="/company/create">Nova empresa</Link>
							<div>
								Mostrar 
								<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
								por página
								<input 
									type="text" 
									placeholder="pesquisar" 
									value={searchTerm} 
									onChange={(e) => setSearchTerm(e.target.value)}
									style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
									/>
							</div>
							<table>
								<thead>
									<tr>
										<th>#</th>
										<th>Nome</th>
										<th>CNPJ</th>
										<th><i className="fa-solid fa-pen-nib"></i></th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map(company => (
										<tr key={company.id}>
											<td>{company.id}</td>
											<td>{company.company_name}</td>
											<td>{company.cnpj}</td>
											<td><Link to={`/company/update/${company.id}`}><i className="fa-solid fa-pen-nib"></i></Link></td>
										</tr>
									))}
								</tbody>
							</table>
							<div>
								<p>Mostrando produtos {startIndex + 1} a {endIndex > totalItems ? totalItems : endIndex} , total: {totalItems}</p>
								<button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
								<button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
								<p>Página {currentPage} de {totalPages}</p>
							</div>
						</div>
					}
					</section>
			</div>
		</Fragment>
	);
}

export default Profile
