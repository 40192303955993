import React from 'react'
import { Link } from 'react-router-dom';

function Header() {

	function closeNav() {
        document.getElementById("sidebar").style.width = "0";
        document.getElementById("sidebar").style.padding = "0";
        document.getElementById("main").style.marginLeft= "0";
        document.getElementById("openbtn").style.display = "block";
        document.getElementById("top_bar").style.height = "50px";
		document.getElementById("top_bar").style.marginBottom = "15px";
        document.getElementById("top_bar").style.opacity = "1";
    }

	function openNav() {
		document.getElementById("sidebar").style.width = "250px";
		document.getElementById("sidebar").style.paddingLeft = "20px";
		document.getElementById("sidebar").style.paddingTop = "60px";
		document.getElementById("sidebar").style.paddingBottom = "20px";
		document.getElementById("main").style.marginLeft = "310px";
		document.getElementById("openbtn").style.display = "none";
		document.getElementById("top_bar").style.height = "0px";
		document.getElementById("top_bar").style.marginBottom = "20px";
		document.getElementById("top_bar").style.opacity = "0";
	}

	return (
		<header>
			{/* Topbar */}
			<div className='top_bar' id="top_bar">
				<div className='main_logo'>
					<b className="openbtn" id='openbtn' onClick={openNav}>☰</b>
				</div>
				<div className='top_links'>
					<p><Link to="/calendar"><i className="fa-solid fa-calendar-days"></i></Link></p>
					<p><Link to="/notification"><i className="fa-solid fa-bell"></i></Link></p>
					<p><Link to="/message"><i className="fa-solid fa-comments"></i></Link></p>
					<p><Link to="/profile"><i className="fa-solid fa-user"></i></Link></p>
					<p><Link to="/company/select"><i className="fa-solid fa-landmark"></i></Link></p>
				</div>
			</div>

			{/* Side Menu */}
			<nav id="sidebar" className="sidebar" >
				<sub className="closebtn" onClick={closeNav} style={{cursor: 'w-resize', fontSize: 25, marginTop: 15}}>×</sub>
				<ul>

					<Link to="/dash"><li><i className="fa-solid fa-chart-column"></i>Dash</li></Link>
					<Link to="/calendar"><li><i className="fa-solid fa-calendar-days"></i>Compromissos</li></Link>


					<h3>Comercial</h3>
					<Link to="/funnel"><li><i className="fa-solid fa-filter-circle-dollar"></i>Funil</li></Link>
					<Link to="/client"><li><i className="fa-solid fa-users"></i>Clientes</li></Link>


					<h3>Orçamentos</h3>
					<Link to="/order"><li><i className="fa-solid fa-cart-shopping"></i>Ordem de venda</li></Link>
					<Link to="/proposal"><li><i className="fa-solid fa-cart-shopping"></i>Propostas comerciais</li></Link>


					<h3>Serviços</h3>
					<Link to="/service"><li><i className="fa-solid fa-pen-nib"></i>Serviços</li></Link>
					<Link to="/os"><li><i className="fa-solid fa-pen-nib"></i>Ordem de serviço</li></Link>


					<h3>Estoque</h3>
					<Link to="/storage"><li><i class="fa-solid fa-ranking-star"></i>Estoque</li></Link>
					<Link to="/supplier"><li><i className="fa-solid fa-truck"></i>Fornecedores</li></Link>
					<Link to="/product"><li><i className="fa-solid fa-parachute-box"></i>Produtos</li></Link>
					<Link to="/product/category"><li><i className="fa-solid fa-tags"></i>Categorias</li></Link>


					<h3>Pós venda</h3>
					<Link to="/warranty"><li><i className="fa-solid fa-shield-halved"></i>Garantias</li></Link>
					<Link to="/return"><li><i className="fa-solid fa-shield-halved"></i>Devoluções</li></Link>


					<h3>Representantes</h3>
					<Link to="/salesman"><li><i className="fa-solid fa-clipboard-user"></i>Representantes</li></Link>
					<Link to="/target"><li><i className="fa-solid fa-shield-halved"></i>Metas de venda</li></Link>
					<Link to="/target/create"><li><i className="fa-solid fa-bolt"></i>Comissões</li></Link>


					<h3>Departamento pessoal</h3>
					<Link to="/employee"><li><i className="fa-solid fa-user-group"></i>Colaboradores</li></Link>
					<Link to="/department"><li><i className="fa-solid fa-users-gear"></i>Setores</li></Link>
					<Link to="/role"><li><i className="fa-solid fa-users-gear"></i>Cargos</li></Link>
					<Link to="/wiki"><li><i className="fa-solid fa-clipboard"></i>Folhas de pagamento</li></Link>
					<Link to="/wiki/create"><li><i className="fa-solid fa-clipboard"></i>Holerites</li></Link>
					<Link to="/file"><li><i className="fa-solid fa-clipboard"></i>Documentos</li></Link>
					<Link to="/bank/account"><li><i className="fa-solid fa-wallet"></i>Contas bancárias</li></Link>


					<h3>Pontos</h3>
					<Link to="/timer"><li><i className="fa-solid fa-user-group"></i>Pontos</li></Link>
					<Link to="/timer/report"><li><i className="fa-solid fa-user-group"></i>Relatório de horas</li></Link>
					<Link to="/timer/create"><li><i className="fa-solid fa-user-group"></i>Registrar ponto</li></Link>


					<h3>Requisições</h3>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>Folgas</li></Link>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>Férias</li></Link>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>FGTS</li></Link>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>INSS</li></Link>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>Adiantamento 13º</li></Link>
					<Link to="/sick-note"><li><i className="fa-solid fa-user-group"></i>Atestados</li></Link>
					<Link to="/benefit"><li><i className="fa-solid fa-user-group"></i>Benefícios</li></Link>


					<h3>Guias e treinamentos</h3>
					<Link to="/wiki"><li><i className="fa-solid fa-graduation-cap"></i>Guias </li></Link>
					<Link to="/wiki"><li><i className="fa-solid fa-graduation-cap"></i>Treinamentos</li></Link>


					<h3>Recursos humanos</h3>					
					<Link to="/wiki"><li><i className="fa-solid fa-clipboard"></i>Advertências</li></Link>
					<Link to="/wiki"><li><i className="fa-solid fa-clipboard"></i>Mediações</li></Link>
					<Link to="/wiki"><li><i className="fa-solid fa-clipboard"></i>Votações</li></Link>
					<Link to="/wiki"><li><i className="fa-solid fa-clipboard"></i>Pesquisas de satisfação</li></Link>
					<Link to="/job"><li><i className="fa-solid fa-user-plus"></i>Vagas abertas</li></Link>
					<Link to="/cv"><li><i className="fa-solid fa-user-plus"></i>Currículos</li></Link>
					<Link to="/candidate"><li><i className="fa-solid fa-user-plus"></i>Candidatos</li></Link>


					<h3>Financeiro</h3>
					<Link to="/wallet"><li><i className="fa-solid fa-wallet"></i>Histórico financeiro</li></Link>
					<Link to="/dre"><li><i className="fa-solid fa-wallet"></i>DRE</li></Link>
					<Link to="/department/max"><li><i className="fa-solid fa-wallet"></i>Balança Comercial.</li></Link>
					<Link to="/wallet/deposit"><li><i className="fa-solid fa-chart-line"></i>Contas a receber</li></Link>
					<Link to="/wallet/takeout"><li><i className="fa-solid fa-chart-line"></i>Contas a pagar</li></Link>
					<Link to="/wallet/charge"><li><i className="fa-solid fa-ghost"></i>Cobranças</li></Link>
					<Link to="/tax"><li><i className="fa-solid fa-ghost"></i>Impostos</li></Link>


					<h3>Pagamentos</h3>
					<Link to="/payment/plan"><li><i className="fa-solid fa-link"></i>Planos de pagamentos</li></Link>
					<Link to="/payment/link"><li><i className="fa-solid fa-link"></i>Links de pagamento.</li></Link>


					<h3>Administração</h3>
					{/* Integração com E-Notas */}
					<Link to="/nfe"><li><i className="fa-solid fa-link"></i>Notas fiscais</li></Link>
					{/* Integração */}
					<Link to="/certificate"><li><i className="fa-solid fa-link"></i>Certificados digitais</li></Link> 
					<Link to="/branch"><li><i className="fa-solid fa-map-location-dot"></i>Filiais</li></Link>


					<h3>Patrimonio</h3>
					<Link to="/gear"><li><i className="fa-solid fa-map-location-dot"></i>Inventário</li></Link>
					<Link to="/gear"><li><i className="fa-solid fa-plus"></i>Adicionar equipamentos</li></Link>
					<Link to="/gear"><li><i className="fa-solid fa-plus"></i>Requisições</li></Link>
					<Link to="/gear"><li><i className="fa-solid fa-plus"></i>Manutenções</li></Link>
					<Link to="/gear"><li><i className="fa-solid fa-plus"></i>Notas fiscais</li></Link>


					<h3>Veículos</h3>
					<Link to="/vehicle"><li><i className="fa-solid fa-truck-moving"></i>Veículos</li></Link>
					<Link to="/vehicle"><li><i className="fa-solid fa-plus"></i>Adicionar veículos</li></Link>
					<Link to="/vehicle"><li><i className="fa-solid fa-plus"></i>Manutenções</li></Link>
					<Link to="/vehicle"><li><i className="fa-solid fa-plus"></i>Revisões</li></Link>
					<Link to="/vehicle"><li><i className="fa-solid fa-plus"></i>Requisições</li></Link>
					<Link to="/support"><li><i className="fa-solid fa-car-burst"></i>Ocorrências</li></Link>


					<h3>Projetos</h3>
					<Link to="/project"><li><i class="fa-solid fa-list-check"></i>Projetos</li></Link>
					<Link to="/project/tag"><li><i class="fa-solid fa-tags"></i>Marcadores</li></Link>
					<Link to="/project/target"><li><i className="fa-solid fa-bookmark"></i>Referências</li></Link>
					<Link to="/project/doc"><li><i className="fa-solid fa-book"></i>Documentações</li></Link>


					<h3>Cotações</h3>
					<Link to="/mail"><li><i className="fa-solid fa-envelope"></i>Cotação correios</li></Link>
					<Link to="/transport"><li><i className="fa-solid fa-route"></i>Cotação transportadora</li></Link>
					<Link to="/transport"><li><i className="fa-solid fa-route"></i>Cotação 55 move</li></Link>


					<h3>Entregas</h3>
					<Link to="/delivery"><li><i className="fa-solid fa-route"></i>Entregas</li></Link>
					<Link to="/delivery"><li><i className="fa-solid fa-route"></i>Rotas</li></Link>
					<Link to="/driver"><li><i className="fa-solid fa-clipboard-user"></i>Entregadores</li></Link>
					<Link to="/address"><li><i className="fa-solid fa-location-dot"></i>Endereços</li></Link>


					<h3>Jurídico</h3>
					<Link to="/contract"><li><i className="fa-solid fa-scale-balanced"></i>Contratos</li></Link>
					<Link to="/contract/model"><li><i className="fa-solid fa-scale-balanced"></i>Modelos</li></Link>
					<Link to="/agreement"><li><i className="fa-solid fa-landmark"></i>Acordos</li></Link>
					{/* Integração com zapsign */}
					<Link to="/signs"><li><i className="fa-solid fa-link"></i>Assinaturas</li></Link> 


					<h3>Marketing</h3>
					{/* Google Maps, linkedin, instagram, etc, busca leads por categoria e localização */}
					<Link to="/lead"><li><i className="fa-solid fa-thumbs-up"></i>Leads</li></Link>	
					<Link to="/coupon"><li><i className="fa-solid fa-gift"></i>Cupons</li></Link>
					<Link to="/nps"><li><i className="fa-solid fa-thumbs-up"></i>Resultados NPS</li></Link>
					<Link to="/campain"><li><i className="fa-solid fa-thumbs-up"></i>Campanhas</li></Link>
					<Link to="/campain"><li><i className="fa-solid fa-thumbs-up"></i>Links</li></Link>


					<h3>Loja virtual</h3>
					<Link to="/display"><li><i className="fa-solid fa-plus"></i>Display</li></Link>
					<Link to="/product/category"><li><i className="fa-solid fa-plus"></i>Categorias</li></Link>


					<h3>Alimentação</h3>
					<h5> ---- Migrar módulo do flux ---- </h5>
					<Link to="/food/ingredient"><li><i className="fa-solid fa-drumstick-bite"></i>Ingredientes</li></Link>
					<Link to="/food/dish"><li><i className="fa-solid fa-utensils"></i>Pratos</li></Link>
					<Link to="/food/menu"><li><i className="fa-solid fa-utensils"></i>Cardápio Virtual</li></Link>
					<Link to="/food/tech"><li><i className="fa-solid fa-utensils"></i>Ficha Técnica</li></Link>
					<Link to="/food/order"><li><i className="fa-solid fa-utensils"></i>Comandas</li></Link>
					<Link to="/food/table"><li><i className="fa-solid fa-utensils"></i>Mesas</li></Link>


					<h3>Suporte</h3>
					<Link to="/food/table"><li><i className="fa-solid fa-headset"></i>Fila de suporte</li></Link>
					<Link to="/food/table"><li><i className="fa-solid fa-headset"></i>Tickets</li></Link>
					<Link to="/food/table"><li><i className="fa-solid fa-headset"></i>Escalar ou transferir ticket</li></Link>
					<Link to="/food/table"><li><i className="fa-solid fa-headset"></i>Ocorrência</li></Link>


					<h3>TI & Help Desk</h3>
					<Link to="/it/table"><li><i className="fa-solid fa-desktop"></i>Fila de chamados</li></Link>
					<Link to="/it/table"><li><i className="fa-solid fa-desktop"></i>Abrir chamado - Manutenção</li></Link>
					<Link to="/it/table"><li><i className="fa-solid fa-desktop"></i>Abrir chamado - Ajuda</li></Link>
					<Link to="/it/table"><li><i className="fa-solid fa-desktop"></i>Escalar ou transferir chamado</li></Link>
					<Link to="/it/software/table"><li><i className="fa-solid fa-desktop"></i>Licença de software</li></Link>
					<Link to="/it/server"><li><i className="fa-solid fa-desktop"></i>Servidores</li></Link>


					<h3>Minha conta</h3>
					<Link to="/profile"><li><i className="fa-solid fa-user"></i>Perfil</li></Link>
					<Link to="/notification"><li><i className="fa-solid fa-bell"></i>Notificações</li></Link>
					<Link to="/message"><li><i className="fa-solid fa-comments"></i>Mensagens</li></Link>
					<Link to="/company/select"><li><i className="fa-solid fa-landmark"></i>Empresas</li></Link>
					<Link to="/login"><li><i className="fa-solid fa-right-from-bracket"></i>Logout</li></Link>
				</ul>
			</nav>
		</header>
	)
}

export default Header
