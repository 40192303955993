import React, {useState} from 'react';
import PaymentMethodsSelect from './selects/PaymentMethodsSelect';
import BankSelect from './selects/BankSelect';
import GeoStateSelect from './selects/GeoStatesSelect';
import CitySelect from './selects/CitySelect';
import CountrySelect from './selects/CountrySelect';

import ProductCategorySelect from './selects/ProductCategorySelect';

function SelectsComponents() {

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
	const [selectedBank, setSelectedBank] = useState('');
	const [productCategorySelect, setProductCategorySelect] = useState('');
	
	const [selectedGeoState, setSelectedGeoState] = useState('');
	const [selectedCity, setSelectedCity] = useState('');
  	const [selectedCountry, setSelectedCountry] = useState('');

	const handleSelect = (value) => {
		setSelectedPaymentMethod(value);
	};

	const handleProductCategorySelect = (value) => {
		setProductCategorySelect(value);
	};

	const handleBankSelect = (value) => {
		setSelectedBank(value);
	};

	const handleGeoStateSelect = (value) => {
		setSelectedGeoState(value);
	};

	const handleCitySelect = (value) => {
		setSelectedCity(value);
	};

  const handleCountrySelect = (value) => {
		setSelectedCountry(value);
	};


    return (
        <div>
            <PaymentMethodsSelect onSelect={handleSelect}/>
			<p>Selecionado: {selectedPaymentMethod}</p>

			<ProductCategorySelect onSelect={handleProductCategorySelect}/>
			<p>Selecionado: {productCategorySelect}</p>
			
            <BankSelect onSelect={handleBankSelect}/>
			<p>Selecionado: {selectedBank}</p>
			
            <GeoStateSelect onSelect={handleGeoStateSelect}/>
            <p>Selecionado: {selectedGeoState}</p>

            <CitySelect onSelect={handleCitySelect}/>
            <p>Selecionado: {selectedCity}</p> 

            <CountrySelect onSelect={handleCountrySelect}/>
            <p>Selecionado: {selectedCountry}</p> 
			
        </div>
    )
}

export default SelectsComponents