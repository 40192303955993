import axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom';

function NpsCreate() {
    const { company_uuid } = useParams();

    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState(null);

    const [score, setScore] = useState('');
	const [review, setReview] = useState('');

	const [submitClicked, setSubmitClicked] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		axios.get(`https://erp-api.55technology.com/v1/company/uuid/` + company_uuid)
		.then(response => {
            if (response.status !== 200) {
                console.log("Empresa não encontrada!")
            } else {
                setCompanyId(response.data.companies[0].id);
                setCompanyName(response.data.companies[0].name);
                console.log("Empresa: " + companyId);
            }
		})
		.catch(error => console.error('Error fetching company:', error));
	});

    const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*"
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/nps",
			method: "POST",
			headers: headersList,
			data: {
				"company_id": companyId,
				"score": score,
				"review": review,
			}
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/nps/ok", { replace: true });

		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

    return (
        <div>            
            <section className="column">
                <form onSubmit={handleInsert}>
                    {
                        companyName == null
                        ? <i className="fa-regular fa-sun rotating-star"></i> 
                        :  <h2>Que nota você daria para {companyName} ?</h2>
                    }
                   
                    <div>
                        <label>Nota:</label>
                        <input 
                            type="number"
                            min={0}
                            max={10}
                            value={score} 
                            onChange={(e) => setScore(e.target.value)} 
                            required 
                        />
                    </div>
                    <div>
                        <label>Review:</label>
                        <input 
                            type="text" 
                            value={review} 
                            onChange={(e) => setReview(e.target.value)} 
                            required 
                        />
                    </div>
                    {
                        submitClicked 
                        ? <i className="fa-regular fa-sun rotating-star"></i> 
                        : <button
                            type="submit"
                            className="btn"
                            disabled={submitClicked}
                            > Cadastrar 
                        </button>
                    }
                </form>
            </section>
        </div>
    );
}

export default NpsCreate
