import React from 'react'
import { Link } from 'react-router-dom'

function LandingPage() {
  return (
    <div className="column">
      <header>
        <h1>BlueERP</h1>
      </header>
      <main className='column'>
        <section className='column'>
          <Link to='/login'>
            <button>Login</button>
          </Link>
          <Link to='/user/create'>
            <button>register</button>
          </Link>
        </section>
        <div>
          <h2>Features and benefits</h2>
          <ul>
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Feature 3</li>
          </ul>
        </div>
        <div>
          <h2>Newsletter</h2>
          <p>Subscribe to our newsletter for updates and promotions.</p>
          <form>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </main>
      <footer>
        <button>Login</button>
        <button>Register</button>
      </footer>
        
    </div>
  )
}

export default LandingPage
