import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function CompanySelect() {
    const [ownerCompanies, setOwnerCompanies] = useState([]);
    const [employeeCompanies, setEmployeeCompanies] = useState([]);
	const [loadingOwner, setLoadingOwner] = useState(true);
	const [inviteId, setInviteId] = useState(0);
	const [loadingEmployee, setLoadingEmployee] = useState(true);


	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		var accessToken = localStorage.getItem('access_token');
		
		axios.get(`https://erp-api.55technology.com/v1/company/owner`, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setOwnerCompanies(response.data.companies);
			setLoadingOwner(false);
		})
		.catch(error => {
			console.error('Error fetching ownerCompanies:', error);
			navigate("/login");
		});

		axios.get(`https://erp-api.55technology.com/v1/employee/company/invite`, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setEmployeeCompanies(response.data.companies);
			setLoadingEmployee(false);
		})
		.catch(error => {
			console.error('Error fetching ownerCompanies:', error);
			navigate("/login");
		});

	}, [navigate]);

	const selectCompany = (company) => {
		localStorage.setItem("selected_company", company);
		navigate('/dash');
	}

	const selectEmployeeCompany = (id, is_accepted) => {
		setInviteId(id);
		if (is_accepted) {
			localStorage.setItem("selected_company", id);
			setModalOpen(false);
			navigate('/dash');
		} else {
			setModalOpen(true);
		}
	}

	const [isModalOpen, setModalOpen] = useState(false);

	const closeModal = () => {
		console.log(inviteId);
		setModalOpen(false);
	};

	const handleOverlayClick = (event) => {
		// Close the modal if the click is outside the modal content
		if (event.target.classList.contains('modal-overlay')) {
		  closeModal();
		}
	};

	const acceptInvite = () => {

		console.log(inviteId);

		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		var accessToken = localStorage.getItem('access_token');

		axios.put('https://erp-api.55technology.com/v1/employee/company/invite/'+inviteId, {}, {
			headers: { "token": accessToken }
		})
		.then(() => {
			setLoadingEmployee(false);
			setLoadingOwner(false);
			alert('Seu convite foi aceito. Seja bem vindo(a)!');
			navigate('/dash')
		})
		.catch(error => {
			alert(error);
		});
	};


	return (
		<Fragment>
			<div id="main">
				<div className="column">
					<h2>Minhas empresas</h2>
					{
						(loadingOwner) 
						? <i className="fa-regular fa-sun rotating-star"></i>
						: <div className="row">
							<Link to="/company/create">
								<div className="select-company-card">
									<i className="fa-solid fa-plus" style={{fontSize:40}}></i>
								</div>
							</Link>
							{ownerCompanies.map(company => (
								<div key={company.id} className="select-company-card" onClick={() => selectCompany(company.id)}>
									<h3>{company.company_name}</h3>
									<p>{company.cnpj}</p>
                                    <p>Proprietário(a)</p>
									<div className="row">
										<p><Link to={`/company/update/${company.id}`}><i className="fa-solid fa-pen-nib"></i></Link></p>
										<p><Link to={`/company/delete/${company.id}`}><i className="fa-solid fa-trash"></i></Link></p>
									</div>
								</div>
							))}
						</div>
					}

					<h2 style={{marginTop: 50}}>Empresas onde sou colaborador(a)</h2>
					{
						(loadingEmployee) 
						? <i className="fa-regular fa-sun rotating-star"></i>
						: <div className="row">
							{employeeCompanies.length === 0 
							? ( <p style={{marginTop: 20}}> Você não é colaborador(a) de nehuma empresa no momento.</p> ) 
							: ( employeeCompanies.map(company => (
								<div className="select-company-card" onClick={() => selectEmployeeCompany(company.employee_id, company.is_accepted)} key={company.id}>
									<h3>{company.company_name}</h3><p></p>
									<p>R$ {company.amount/100}</p>
									<p>{company.permission_name}</p>
									{ !company.is_accepted 
										? <p style={{color: 'red'}}>NOVO CONVITE</p>
										: <p>Entrar</p>
									}									
								</div>
								))
							)}
						</div>
					}

					{isModalOpen && (
						<div className="modal-overlay" onClick={handleOverlayClick}>
							<div className="modal">
								<i className="fa-regular fa-circle-xmark close-button" onClick={closeModal}></i>
								<h3>Você foi convidado(a) a fazer parte de uma nova empresa!</h3>
								<p>Seja bem vindo(a)</p>
								<button style={{margin: 15}} onClick={() => acceptInvite()}>Aceitar convite</button>

								{/* POR FAZER -> Quando clicar em recusar, abrir uma tela para ver o motivo e registrar esse evento, assim podemos analisar a usabilidade do convite e medir quantas empresas escolhem a pessoa errada no cadastro de colaboradores. */}
								<button style={{margin: 15}} onClick={closeModal}>Recusar convite</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
}

export default CompanySelect
