import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EmployeeList() {
	const [employees, setEmployees] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		var company_id = localStorage.getItem('selected_company');

		axios.get(`https://erp-api.55technology.com/v1/company/employee/` + company_id, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setEmployees(response.data.employees);
			setLoading(false);
		  })
		.catch(error => console.error('Error fetching products:', error));
	}, [navigate]);

	const filteredProducts = employees.filter(employee =>
		employee.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const totalItems = filteredProducts.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = filteredProducts.slice(startIndex, endIndex);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	// Gambiarra pra formatar o created_at. Que saudades do php...
	function formatDateString(inputDate) {
		const date = new Date(inputDate);
	  
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
		const year = date.getFullYear();
	  
		return `${day}/${month}/${year}`;
	}
	  

	return (
		<Fragment>
			<Header/>
			<div id="main">
				<p>Gráfico pizza: Status dos funcionários. % ativos, % férias, % atestado, % fantantes.</p>
				<p>Gráfico pizza: % de funcionários que aceitaram, % dos funcionários que não aceitaram.</p>

		 		<section className="column">
				{
					(loading) 
					? <i className="fa-regular fa-sun rotating-star"></i>
					: <div className="column">
						<h2>Colaboradores</h2>
			 			
						<div className='table-option-top row'>
							<div>
								<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value="25">Mostrar 25 por página</option>
									<option value="50">Mostrar 50 por página</option>
									<option value="100">Mostrar 100 por página</option>
								</select>
							</div>
							<input 
								type="search" 
								placeholder="pesquisar" 
								value={searchTerm} 
								onChange={(e) => setSearchTerm(e.target.value)}
								style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
							/>
							<Link to='/employee/create'>
								<button>
								<i className="fa-solid fa-user-plus"></i>
									Novo Colaborador
								</button>
							</Link>
							</div>
							<table>
								<thead>
									<tr>
										<th>#</th>
										<th>Nome</th>
										<th>Email</th>
										<th>Telefone</th>
										<th>Cadastro</th>
										<th>
											<div className="tooltip">Permissão
												<span className="tooltiptext">Permissão dentro do sistema. Financeiro tem acesso apenas aos dados financeiros, entregadores tem acesso apenas as entregas...</span>
											</div>
										</th>
										<th>
											<div className="tooltip">Aceitou?
												<span className="tooltiptext">Ao cadastrar um funcionário, ele deve aceitar o convite em suas notificações.</span>
											</div>
										</th>
										<th><i className="fa-solid fa-pen-nib"></i></th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map(employee => (
										<tr>
											<td>{employee.id}</td>
											<td>{employee.employee_name}</td>
											<td>{employee.email}</td>
											<td>{employee.phone}</td>
											<td>{formatDateString(employee.created_at)}</td>
											<td>{employee.permission}</td>
											<td>{employee.is_accepted ? "Sim" : "Não"}</td>
											<td><Link to={`/employee/update/${employee.id}`}><i className="fa-solid fa-pen-nib"></i></Link></td>
										</tr>
									))}
								</tbody>
							</table>
							<div className='table-option-bottom row'>
								<p>Mostrando ítens {startIndex + 1} a {endIndex > totalItems ? totalItems : endIndex}. </p>
								<p>Total: {totalItems} ítens</p>
								<p>Página {currentPage} de {totalPages}</p>
								<div>
									<button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
									<button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
								</div>
							</div>
						</div>
					}
				</section>
			</div>
		</Fragment>
	);
}

export default EmployeeList
