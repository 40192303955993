import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function NpsList() {
	const [nps, setClients] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(true);
	const [avgScore, setAvgScore] = useState();
	const [npsQtd, setNpsQtd] = useState();

	const navigate = useNavigate();


	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		var company_id = localStorage.getItem('selected_company');

		axios.get(`https://erp-api.55technology.com/v1/nps/company/` + company_id, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setClients(response.data.nps);
			setLoading(false);
		  })
		.catch(error => console.error('Error fetching nps:', error));



		axios.get(`https://erp-api.55technology.com/v1/nps/average/` + company_id, {
			headers: { "token": accessToken }
		})
		.then(response => {
			setAvgScore(response.data.avg.score);
			setNpsQtd(response.data.avg.qtd);
			setLoading(false);
		  })
		.catch(error => console.error('Error fetching nps:', error));


		
	}, [navigate]);

	const filteredProducts = nps.filter(nps =>
		nps.review.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const totalItems = filteredProducts.length;
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = filteredProducts.slice(startIndex, endIndex);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const copyToClipboard = () => {

		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		var company_id = localStorage.getItem('selected_company');

		// Pega o link da company
		axios.get(`https://erp-api.55technology.com/v1/nps/link/` + company_id, {
			headers: { "token": accessToken }
		})
		.then(response => {
			console.log(response.data.link);

			navigator.clipboard.writeText(response.data.link)
				.then(() => {
					console.log('Text successfully copied to clipboard');
					alert(response.data.link + " copiado")
				})
				.catch(err => {
					console.error('Unable to copy text to clipboard', err);
				});
		  })
		.catch(error => console.error('Error fetching nps link:', error));
	  };

	return (
		<Fragment>
			<Header/>
			<div id="main">
		 		<section className="column">
				{
					(loading) 
					? <i className="fa-regular fa-sun rotating-star"></i>
					: <div className="column">
						<h2>NPS</h2>

						<p>Nota média: {avgScore}</p>
						<p>Avaliações: {npsQtd}</p>
			 			
						<div className='table-option-top row'>
							<div>
								<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value="25">Mostrar 25 por página</option>
									<option value="50">Mostrar 50 por página</option>
									<option value="100">Mostrar 100 por página</option>
								</select>
							</div>
							<input 
								type="search" 
								placeholder="pesquisar" 
								value={searchTerm} 
								onChange={(e) => setSearchTerm(e.target.value)}
								style={{margin: 20, paddingLeft: 10, paddingRight: 10}}
							/>
							<button onClick={copyToClipboard}>
								<i className="fa-solid fa-user-plus"></i>
								Novo NPS
							</button>
							</div>
							<table>
								<thead>
									<tr>
										<th>#</th>
										<th>Nota</th>
										<th>Review</th>
										<th>Criado em</th>
									</tr>
								</thead>
								<tbody>
									{currentItems.map(nps => (
										<tr key={nps.id}>
											<td>{nps.id}</td>
											<td>{nps.score}</td>
											<td>{nps.review}</td>
											<td>{nps.created_at}</td>
										</tr>
									))}
								</tbody>
							</table>
							<div className='table-option-bottom row'>
								<p>Mostrando ítens {startIndex + 1} a {endIndex > totalItems ? totalItems : endIndex}. </p>
								<p>Total: {totalItems} ítens</p>
								<p>Página {currentPage} de {totalPages}</p>
								<div>
									<button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
									<button onClick={handleNextPage} disabled={currentPage === totalPages}>Próxima</button>
								</div>
							</div>
						</div>
					}
				</section>
			</div>
		</Fragment>
	);
}

export default NpsList
