import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function ProjectTaskInsert() {

    const { goal_uuid } = useParams();

	const [name, setName] = useState('');
	const [dueDate, setDueDate] = useState('');
	const [description, setDescription] = useState('');

    const [token, setToken] = useState('');
	const [submitClicked, setSubmitClicked] = useState(false);


	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);
	}, [navigate]);

	const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/project/task",
			method: "POST",
			headers: headersList,
			data: {
				"goal_uuid": goal_uuid,
				"name": name,
				"description": description,
				"due_date": dueDate,
			}
		};

		try {
			const response = await axios.request(reqOptions);

			console.log('create successful:', response.data);

			setSubmitClicked(false);

			navigate("/project/goal/"+goal_uuid, { replace: true });

		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Nova tarefa</h2>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>

                        <div>
							<label>Descrição:</label>                            
						</div>
                        <div>
                            <textarea name="description" id="" value={description} onChange={(e) => setDescription(e.target.value)}  cols="30" rows="10"></textarea>
                        </div>

						<div>
							<label>Prazo:</label>
							<input 
								type="date" 
								value={dueDate} 
								onChange={(e) => setDueDate(e.target.value)} 
								required 
							/>
						</div>

						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar 
							</button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default ProjectTaskInsert
