import React, { Fragment } from 'react';
import Header from '../../../layout/Header';
import { MapContainer, TileLayer, Polygon, FeatureGroup, Popup, Circle } from 'react-leaflet';


function BranchList() {

  const center = [-27.612523,-48.6436261]

  const polygon = [
    [-27.404623,-48.3036281], // Norte da ilha (mar)
    [-27.284623,-48.5036281], // Gov Celso Ramos (mar)
    [-27.304623,-48.7036281], // Gov Celso Ramos (terra)
    [-27.504623,-48.8036281], // Ant Carlos
    [-27.685623,-48.8556561], // Santo Amaro
    [-27.995623,-48.8056561], // Garopaba (terra)
    [-28.101009,-48.7254261], // Garopaba (meio L)
    [-28.100019,-48.6254261], // Garopaba (meio R)
    [-28.000009,-48.5654261], // Garopaba (mar)
  ]

  const limeOptions = { color: '#004719' }
  const greenOptions = { color: 'red', fillColor: 'red' }

  return (
    <Fragment>
      <Header/>
      <div id="main">
        <h2>Filiais</h2>

        <section id="delivery-area">
          <MapContainer center={center} zoom={6} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Polygon pathOptions={limeOptions} positions={polygon} />

            <FeatureGroup pathOptions={greenOptions}>
              <Popup>Matriz SC</Popup>
              <Circle center={[-27.692512949083742, -48.68893902357324]} radius={25000} />
            </FeatureGroup>
            
            <FeatureGroup pathOptions={greenOptions}>
              <Popup>Filial SP</Popup>
              <Circle center={[-23.558383115797398, -46.65956716239116]} radius={25000} />
            </FeatureGroup>
            
            <FeatureGroup pathOptions={greenOptions}>
              <Popup>Filial MVD</Popup>
              <Circle center={[-34.86871938075364, -56.12305030652576]} radius={25000} />
            </FeatureGroup>
          </MapContainer>
        </section>
      </div>
    </Fragment>
  )
}

export default BranchList
