import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function BankAccountList() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Contas bancarias</h2>
			</div>
		</Fragment>
	)
}

export default BankAccountList
