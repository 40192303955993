import React, {useState, useEffect} from 'react';
import axios from 'axios';

const BankSelect = ({onSelect}) => {
	const [bankList, setBankList] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(()=>{
		axios.get("https://erp-api.55technology.com/v1/bank/br")
		.then (response => {
				console.table(response.data.banks);
				setBankList(response.data.banks);
		})
		.catch (error => {
				console.error("Erro na API", error);
		});
	},[]);

	const handleInputChange = (event) => {
		setSelectedValue(event.target.value);
		onSelect(event.target.value);
	};

	return (
		<div>
			<label htmlFor="bankList">Banco:</label>
			
			<select onChange={handleInputChange} value={selectedValue}>
				{bankList.map(bank => (
					<option value={bank.id}>{bank.name}</option>
				))}
			</select>
		</div>
	);
}
export default BankSelect;