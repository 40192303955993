import React, { Fragment } from 'react'

import Header from '../../../layout/Header'

const OrderInsert = () => {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Novo pedido</h2>
			</div>
		</Fragment>
	);
};

export default OrderInsert;
