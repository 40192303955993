import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProductSelect from '../../../selects/ProductSelect';
import UnitSelect from '../../../selects/UnitSelect';
import SupplierSelect from '../../../selects/SupplierSelect';

function AddressInsert() {
	const [companyId, setCompanyId] = useState('');

	const [amount, setAmount] = useState('');

	const [selectedProduct, setSelectedProduct] = useState('');
	const [selectedUnit, setSelectedUnit] = useState('');
	const [selectedSupplier, setSelectedSupplier] = useState('');

	const [quantity, setQuantity] = useState('');
	const [purchaseDate, setPurchaseDate] = useState('');
	const [token, setToken] = useState('');

	const [submitClicked, setSubmitClicked] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);

	const handleProductSelect = (value) => {
		setSelectedProduct(value);
	};

	const handleSupplierSelect = (value) => {
		setSelectedSupplier(value);
	};

	const handleUnitSelect = (value) => {
		setSelectedUnit(value);
	};

	const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/storage",
			method: "POST",
			headers: headersList,
			data: {
				"amount": amount,
				"company_id": companyId,
				"product_id": selectedProduct,
				"quantity": quantity,
				"unit_id": selectedUnit,
				"purchase_date": purchaseDate,
				"supplier_id": selectedSupplier,
			}
		};

		// {
		//     "company_id": 10,
		//     "product_id": 11,
		//     "quantity": 10,
		//     "unit_id": 1,
		//     "purchase_date": 25,
		//     "amount": 1500,
		//     "supplier_id": 6
		// }

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/storage", { replace: true });
		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Nova compra de material</h2>
						
						<div>
							<label>Quantidade:</label>
							<input 
								type="number" 
								value={quantity} 
								onChange={(e) => setQuantity(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Valor total (de todos os ítens):</label>
							<input 
								type="number" 
								value={amount} 
								onChange={(e) => setAmount(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<ProductSelect onSelect={handleProductSelect}/>
							{/* <p>{selectedProduct}</p> */}
						</div>
						<div>
							<UnitSelect onSelect={handleUnitSelect}/>
							{/* <p>{selectedUnit}</p> */}
						</div>
						<div>
							<label>Data da compra:</label>
							<input 
								type="date" 
								value={purchaseDate} 
								onChange={(e) => setPurchaseDate(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<SupplierSelect onSelect={handleSupplierSelect}/>
							{/* <p>{selectedSupplier}</p> */}
						</div>
						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar 
							</button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default AddressInsert
