import React from 'react'

function ProposalInsert() {
  return (
    <div>
      <h2>Nova proposta</h2>
    </div>
  )
}

export default ProposalInsert
