import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function AgreementList() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Acordos</h2>
			</div>
		</Fragment>
  	)
}

export default AgreementList
