import React, { Fragment, useState } from 'react'
import Header from '../../../layout/Header'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RoleInsert() {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [company_id, setCompanyId] = useState('');
	const navigate = useNavigate();

	const handleInsert = async (e) => {
		e.preventDefault();

		const headersList = {
			"Accept": "*/*",
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/role",
			method: "POST",
			headers: headersList,
			data: {
				company_id: company_id,
				name: name,
				description: description
			}
		};

		try {
			const response = await axios.request(reqOptions);

			console.log('create successful:', response.data);

			// Navigate to /dash route
			navigate("/role", { replace: true });
		} catch (error) {
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<h2>Novo cargo</h2>
					<form onSubmit={handleInsert}>
						<div>
							<label>Company Id:</label>
							<input 
								type="number" 
								value={company_id} 
								onChange={(e) => setCompanyId(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Name:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Description:</label>
							<input 
								type="text" 
								value={description} 
								onChange={(e) => setDescription(e.target.value)} 
								required 
							/>
						</div>
						<button type="submit">Cadastrar</button>
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default RoleInsert
