import React, { Fragment } from 'react'
import Header from '../../layout/Header'

function Messages() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Mensagens</h2>
			</div>
		</Fragment>
	)
}

export default Messages
