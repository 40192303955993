import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function FileInsert() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Novo documento (eviar)</h2>
			</div>
		</Fragment>
	)
}

export default FileInsert
