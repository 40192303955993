import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function DepartmentInsert() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Novo setor (departamento)</h2>
			</div>
		</Fragment>
	)
}

export default DepartmentInsert
