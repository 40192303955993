import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SupplierSelect = ({onSelect}) => {
		const [supplierList, setSupplierList] = useState([]);
		const [selectedValue, setSelectedValue] = useState('');

		const navigate = useNavigate();

		useEffect(()=>{

			if (!localStorage.getItem('access_token')) {
				navigate("/login");
			}
	
			if (!localStorage.getItem('selected_company')) {
				navigate("/company/select");
			}
	
			var accessToken = localStorage.getItem('access_token');
			var company_id = localStorage.getItem('selected_company');
	
			axios.get(`https://erp-api.55technology.com/v1/supplier/company/` + company_id, {
				headers: { "token": accessToken }
			})
				.then (response => {
					console.table(response.data.suppliers);
					setSupplierList(response.data.suppliers);
				})
				.catch (error => {
					console.error("Erro na API", error);
				});
		},[navigate]);

		const handleInputChange = (event) => {
			setSelectedValue(event.target.value);
			onSelect(event.target.value);
		};

		return (
			<div>
				<label htmlFor="supplierList">Fornecedor:</label>

				<select onChange={handleInputChange} value={selectedValue}>
					{supplierList.map(supplier => (
						<option value={supplier.id}>{supplier.supplier_name}</option>
					))}
				</select>
			</div>
		);
}
export default SupplierSelect;