import React from 'react';
import './App.css'; // Import your CSS file

import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/views/onboard/LandingPage';
import Dash from './components/views/dash/Dash';
import FoodTechInsert from './components/views/food/tech/FoodTechInsert';
import FoodTechList from './components/views/food/tech/FoodTechList';
import MenuInsert from './components/views/food/menu/MenuInsert';
import MenuList from './components/views/food/menu/MenuList';
import DishList from './components/views/food/dish/DishList';
import DishInsert from './components/views/food/dish/DishInsert';
import IngredientInsert from './components/views/food/ingredient/IngredientInsert';
import IngredientList from './components/views/food/ingredient/IngredientList';
import NpsList from './components/views/marketing/nps/NpsList';
import CouponInsert from './components/views/marketing/coupon/CouponInsert';
import CouponList from './components/views/marketing/coupon/CouponList';
import LeadSearch from './components/views/marketing/lead/LeadSearch';
import Signature from './components/views/management/signatures/Signature';
import FileInsert from './components/views/juridic/file/FileInsert';
import FileList from './components/views/juridic/file/FileList';
import AgreementInsert from './components/views/juridic/agreement/AgreementInsert';
import AgreementList from './components/views/juridic/agreement/AgreementList';
import ContractList from './components/views/juridic/contract/ContractList';
import ContractInsert from './components/views/juridic/contract/ContractInsert';
import DeliveryInsert from './components/views/logistic/delivery/DeliveryInsert';
import DeliveryList from './components/views/logistic/delivery/DeliveryList';
import VehicleInsert from './components/views/logistic/vehicle/VehicleInsert';
import VehicleList from './components/views/logistic/vehicle/VehicleList';
import AddressInsert from './components/views/logistic/address/AddressInsert';
import DriverInsert from './components/views/logistic/driver/DriverInsert';
import DriverList from './components/views/logistic/driver/DriverList';
import StorageList from './components/views/logistic/storage/StorageList';
import StorageInsert from './components/views/logistic/storage/StorageInsert';
import TransportInsert from './components/views/logistic/transport/TransportInsert';
import TransportList from './components/views/logistic/transport/TransportList';
import MailInsert from './components/views/logistic/mail/MailInsert';
import MailList from './components/views/logistic/mail/MailList';
import ProjectInsert from './components/views/project/project/ProjectInsert';
import ProjectList from './components/views/project/project/ProjectList';
import SupportInsert from './components/views/juridic/support/SupportInsert';
import SupportList from './components/views/juridic/support/SupportList';
import Report from './components/views/management/reports/Report';
import NfeInsert from './components/views/management/nfe/NfeInsert';
import NfeList from './components/views/management/nfe/NfeList';
import CertificateInsert from './components/views/management/certificate/CertificateInsert';
import CertificateList from './components/views/management/certificate/CertificateList';
import EventInsert from './components/views/management/event/EventInsert';
import EventList from './components/views/management/event/EventList';
import SupplierInsert from './components/views/management/supplier/SupplierInsert';
import SupplierList from './components/views/management/supplier/SupplierList';
import ClientInsert from './components/views/management/client/ClientInsert';
import ClientList from './components/views/management/client/ClientList';
import OrderList from './components/views/sale/order/OrderList';
import OrderInsert from './components/views/sale/order/OrderInsert';
import ProductList from './components/views/sale/product/ProductList';
import ProductInsert from './components/views/sale/product/ProductInsert';
import ReturnInsert from './components/views/sale/returns/ReturnInsert';
import ReturnList from './components/views/sale/returns/ReturnList';
import OsList from './components/views/sale/os/OsList';
import OsInsert from './components/views/sale/os/OsInsert';
import WarrantyInsert from './components/views/sale/warranty/WarrantyInsert';
import WarrantyList from './components/views/sale/warranty/WarrantyList';
import TargetInsert from './components/views/sale/target/TargetInsert';
import TargetList from './components/views/sale/target/TargetList';
import SalesmanInsert from './components/views/sale/salesman/SalesmanInsert';
import SalesmanList from './components/views/sale/salesman/SalesmanList';
import EmployeeInsert from './components/views/rh/employee/EmployeeInsert';
import EmployeeList from './components/views/rh/employee/EmployeeList';
import TimerInsert from './components/views/rh/timer/TimerInsert';
import TimerList from './components/views/rh/timer/TimerList';
import SickNoteList from './components/views/rh/sick-notes/SickNoteList';
import SickNoteInsert from './components/views/rh/sick-notes/SickNoteInsert';
import BenefitInsert from './components/views/rh/benefits/BenefitInsert';
import BenefitList from './components/views/rh/benefits/BenefitList';
import DepartmentList from './components/views/rh/department/DepartmentList';
import DepartmentInsert from './components/views/rh/department/DepartmentInsert';
import RoleList from './components/views/rh/role/RoleList';
import RoleInsert from './components/views/rh/role/RoleInsert';
import WikiDetail from './components/views/rh/wiki/WikiDetail';
import WikiInsert from './components/views/rh/wiki/WikiInsert';
import WikiList from './components/views/rh/wiki/WikiList';
import Wallet from './components/views/finance/wallet/Wallet';
import Dre from './components/views/finance/dre/Dre';
import DepartmentMax from './components/views/rh/department/DepartmentMax';
import BankAccountInsert from './components/views/finance/bank-account/BankAccountInsert';
import BankAccountList from './components/views/finance/bank-account/BankAccountList';
import BankList from './components/views/finance/bank/BankList';
import PaymentLinkInsert from './components/views/finance/payment/PaymentLinkInsert';
import PaymentLinkList from './components/views/finance/payment/PaymentLinkList';
import PaymentPlanInsert from './components/views/finance/payment/PaymentPlanInsert';
import PaymentPlanList from './components/views/finance/payment/PaymentPlanList';
import DepositInsert from './components/views/finance/wallet/DepositInsert';
import DepositList from './components/views/finance/wallet/DepositList';
import TakeoutList from './components/views/finance/wallet/TakeoutList';
import ChargeList from './components/views/finance/wallet/ChargeList';
import ChargeInsert from './components/views/finance/wallet/ChargeInsert';
import Login from './components/views/onboard/Login';
import FoodOrderList from './components/views/food/food_order/FoodOrderList';
import FoodOrderInsert from './components/views/food/food_order/FoodOrderInsert';
import FoodTableList from './components/views/food/table/FoodTableList';
import FoodTableInsert from './components/views/food/table/FoodTableInsert';
import BranchList from './components/views/management/branch/BranchList';
import BranchInsert from './components/views/management/branch/BranchInsert';
import Profile from './components/views/onboard/Profile';
import Messages from './components/views/onboard/Messages';
import Notification from './components/views/onboard/Notification';
import Calendar from './components/views/onboard/Calendar';
import UserCreate from './components/views/onboard/user/UserCreate';
import CompanyCreate from './components/views/onboard/company/CompanyCreate';
import CompanyUpdate from './components/views/onboard/company/CompanyUpdate';
import CompanySelect from './components/views/onboard/company/CompanySelect';
import SelectsComponents from './components/selects';
import Funnel from './components/views/sale/funnel/Funnel';
import ProposalList from './components/views/sale/proposal/ProposalList';
import ProposalInsert from './components/views/sale/proposal/ProposalInsert';

import PaymentSubscription from './components/views/dash/PaymentSubscription';
import NpsCreate from './components/views/marketing/nps/NpsCreate';
import NpsOk from './components/views/marketing/nps/NpsOk';
import AddressList from './components/views/logistic/address/AddressList';
import TaxList from './components/views/finance/tax/TaxList';
import TaxInsert from './components/views/finance/tax/TaxInsert';
import ProjectGoalList from './components/views/project/target/ProjectGoalList';
import ProjectGoalInsert from './components/views/project/target/ProjectGoalInsert';
import ProjectTaskList from './components/views/project/task/ProjectTaskList';
import ProjectTaskInsert from './components/views/project/task/ProjectTaskInsert';
import ProjectTagList from './components/views/project/tag/ProjectTagList';
import ProjectTagInsert from './components/views/project/tag/ProjectTagInsert';
import ProductCategoryList from './components/views/sale/product/ProductCategoryList';
import ProductCategoryInsert from './components/views/sale/product/ProductCategoryInsert';

function App() {
	return (		
		<Router>
			<Routes>
				{/* Onboard */}
				<Route path='/' element={<LandingPage/>} />
				<Route path='/login' element={<Login/>} />
				<Route path='/user/create' element={<UserCreate/>} />
				<Route path='/user/payment' element={<PaymentSubscription/>} />

				{/* Company */}
				<Route path='/company/select' element={<CompanySelect/>} />
				<Route path='/company/create' element={<CompanyCreate/>} />
				<Route path='/company/update/:id' element={<CompanyUpdate/>} />

				{/* Dash */}
				<Route path='/dash' element={<Dash/>} />
				<Route path='/profile' element={<Profile/>} />
				<Route path='/message' element={<Messages/>} />
				<Route path='/notification' element={<Notification/>} />
				<Route path='/calendar' element={<Calendar/>} />

				{/* Vendas */}
				<Route path='/funnel' element={<Funnel/>} />
				<Route path='/proposal' element={<ProposalList/>} />
				<Route path='/proposal/create' element={<ProposalInsert/>} />
				<Route path='/order' element={<OrderList/>} />
				<Route path='/order/create' element={<OrderInsert/>} />

				<Route path='/product' element={<ProductList/>} />
				<Route path='/product/create' element={<ProductInsert/>} />
				
				<Route path='/product/category' element={<ProductCategoryList/>} />
				<Route path='/product/category/create' element={<ProductCategoryInsert/>} />


				<Route path='/return' element={<ReturnList/>} />
				<Route path='/return/create' element={<ReturnInsert/>} />
				<Route path='/os' element={<OsList/>} />
				<Route path='/os/create' element={<OsInsert/>} />
				<Route path='/warranty/create' element={<WarrantyInsert/>} />
				<Route path='/warranty' element={<WarrantyList/>} />
				<Route path='/target/create' element={<TargetInsert/>} />
				<Route path='/target' element={<TargetList/>} />
				<Route path='/salesman/create' element={<SalesmanInsert/>} />
				<Route path='/salesman' element={<SalesmanList/>} />

				{/* RH */}
				<Route path='/employee' element={<EmployeeList/>} />
				<Route path='/employee/create' element={<EmployeeInsert/>} />
				<Route path='/timer' element={<TimerList/>} />
				<Route path='/timer/create' element={<TimerInsert/>} />
				<Route path='/sick-note' element={<SickNoteList/>} />
				<Route path='/sick-note/create' element={<SickNoteInsert/>} />
				<Route path='/benefit/create' element={<BenefitInsert/>} />
				<Route path='/benefit' element={<BenefitList/>} />
				<Route path='/department' element={<DepartmentList/>} />
				<Route path='/departmen/createt' element={<DepartmentInsert/>} />
				<Route path='/role' element={<RoleList/>} />
				<Route path='/role/create' element={<RoleInsert/>} />
				<Route path='/wiki/id' element={<WikiDetail/>} />
				<Route path='/wiki/create' element={<WikiInsert/>} />
				<Route path='/wiki' element={<WikiList/>} />

				{/* <h3>Financeiro</h3> */}
				<Route path='/wallet' element={<Wallet/>} />
				<Route path='/dre' element={<Dre/>} />
				<Route path='/department/max' element={<DepartmentMax/>} />
				<Route path='/bank' element={<BankList/>} />
				<Route path='/bank/account/create' element={<BankAccountInsert/>} />
				<Route path='/bank/account' element={<BankAccountList/>} />
				<Route path='/wallet/deposit/create' element={<DepositInsert/>} />
				<Route path='/wallet/deposit' element={<DepositList/>} />
				<Route path='/wallet/takeout' element={<TakeoutList/>} />
				<Route path='/wallet/takeout/create' element={<TargetInsert/>} />
				<Route path='/wallet/charge' element={<ChargeList/>} />
				<Route path='/wallet/charge/create' element={<ChargeInsert/>} />
				<Route path='/payment/link/create' element={<PaymentLinkInsert/>} />
				<Route path='/payment/link' element={<PaymentLinkList/>} />
				<Route path='/payment/plan/create' element={<PaymentPlanInsert/>} />
				<Route path='/payment/plan' element={<PaymentPlanList/>} />
				
				{/* OK */} <Route path='/tax' element={<TaxList/>} />
				{/* OK */} <Route path='/tax/create' element={<TaxInsert/>} />						

				{/* <h3>Administração</h3> */}
				<Route path='/client/create' element={<ClientInsert/>} />
				<Route path='/client' element={<ClientList/>} />
				<Route path='/supplier/create' element={<SupplierInsert/>} />
				<Route path='/supplier' element={<SupplierList/>} />
				<Route path='/event/create' element={<EventInsert/>} />
				<Route path='/event' element={<EventList/>} />
				<Route path='/branch' element={<BranchList/>} />
				<Route path='/branch/create' element={<BranchInsert/>} />
				<Route path='/certificate/create' element={<CertificateInsert/>} />
				<Route path='/certificate' element={<CertificateList/>} />
				<Route path='/nfe/create' element={<NfeInsert/>} />
				<Route path='/nfe' element={<NfeList/>} />
				<Route path='/reports' element={<Report/>} />
				<Route path='/support' element={<SupportList/>} />
				<Route path='/support/create' element={<SupportInsert/>} />


				{/* <h3>Projetos</h3> */}
				{/* OK */} <Route path='/project' 									element={<ProjectList/>} 		/>
				{/* OK */} <Route path='/project/create/' 							element={<ProjectInsert/>} 		/>

				{/* OK */} <Route path='/project/goal/:project_uuid' 				element={<ProjectGoalList/>} 	/>
				{/* OK */} <Route path='/project/goal/create/:project_uuid' 		element={<ProjectGoalInsert/>} 	/>

				{/* OK */} <Route path='/project/task/:goal_uuid' 					element={<ProjectTaskList/>} 	/>
				{/* OK */} <Route path='/project/task/create/:goal_uuid' 			element={<ProjectTaskInsert/>} 	/>

				{/* OK */} <Route path='/project/tag/' 							element={<ProjectTagList/>} 	/>
				{/* OK */} <Route path='/project/tag/create/' 					element={<ProjectTagInsert/>} 	/>

				{/* FAZER */} <Route path='/project/warranty/:project_uuid' 		element={<ProjectTagList/>} 	/>
				{/* FAZER */} <Route path='/project/warranty/create/:project_uuid' 	element={<ProjectTagList/>} 	/>

				{/* FAZER */} <Route path='/project/ref/:project_uuid' 				element={<ProjectTagList/>} 	/>
				{/* FAZER */} <Route path='/project/ref/create/:project_uuid' 		element={<ProjectTagList/>} 	/>

				{/* FAZER */} <Route path='/project/doc/:project_uuid' 				element={<ProjectTagList/>} 	/>
				{/* FAZER */} <Route path='/project/doc/create/:project_uuid' 		element={<ProjectTagList/>} 	/>


				{/* <h3>Logística</h3> */}
				<Route path='/mail/create' element={<MailInsert/>} />
				<Route path='/mail' element={<MailList/>} />
				<Route path='/transport/create' element={<TransportInsert/>} />
				<Route path='/transport' element={<TransportList/>} />
				<Route path='/storage' element={<StorageList/>} />
				<Route path='/storage/create' element={<StorageInsert/>} />
				<Route path='/driver' element={<DriverList/>} />
				<Route path='/driver/create' element={<DriverInsert/>} />
				<Route path='/address/' element={<AddressList/>} />
				<Route path='/address/create' element={<AddressInsert/>} />
				<Route path='/vehicle' element={<VehicleList/>} />
				<Route path='/vehicle/create' element={<VehicleInsert/>} />
				<Route path='/delivery' element={<DeliveryList/>} />
				<Route path='/delivery/create' element={<DeliveryInsert/>} />

				{/* <h3>Jurídico</h3> */}
				<Route path='/contract' element={<ContractList/>} />
				<Route path='/contract/create' element={<ContractInsert/>} />
				<Route path='/agreement' element={<AgreementList/>} />
				<Route path='/agreement/create' element={<AgreementInsert/>} />
				<Route path='/file' element={<FileList/>} />
				<Route path='/file/create' element={<FileInsert/>} />
				<Route path='/signs' element={<Signature/>} />

				{/* Marketing */}
				<Route path='/lead' element={<LeadSearch/>} />
				<Route path='/coupon' element={<CouponList/>} />
				<Route path='/coupon/create' element={<CouponInsert/>} />
				<Route path='/nps' element={<NpsList/>} />							
				<Route path='/nps/ok' element={<NpsOk/>} />							{/* OK */}
				<Route path='/nps/create/:company_uuid' element={<NpsCreate/>} />	{/* OK */}

				{/* Restaurantes */}
				<Route path='/food/ingredient' element={<IngredientList/>} />
				<Route path='/food/ingredient/create' element={<IngredientInsert/>} />
				<Route path='/food/dish' element={<DishList/>} />
				<Route path='/food/dish/create' element={<DishInsert/>} />
				<Route path='/food/menu' element={<MenuList/>} />
				<Route path='/food/menu/create' element={<MenuInsert/>} />
				<Route path='/food/tech/' element={<FoodTechList/>} />
				<Route path='/food/tech/create' element={<FoodTechInsert/>} />
				<Route path='/food/table' element={<FoodTableList/>} />
				<Route path='/food/table/create' element={<FoodTableInsert/>} />
				<Route path='/food/order' element={<FoodOrderList/>} />
				<Route path='/food/oder/create' element={<FoodOrderInsert/>} />


				{/* Testes */}
				<Route path='/selects' element={<SelectsComponents/>} />
			</Routes>
		</Router>
	);
}

export default App;