import React, {useState, useEffect} from 'react';
import axios from 'axios';

const CountrySelect = ({onSelect}) => {
	const [countryList, setCountryList] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(()=>{
		axios.get("https://erp-api.55technology.com/v1/country")
		.then (response => {
			console.table(response.data.countries);
			setCountryList(response.data.countries);
		})
		.catch (error => {
			console.error("Erro na API", error);
		});
	},[]);

	const handleInputChange = (event) => {
		setSelectedValue(event.target.value);
		onSelect(event.target.value);
	};

	return (
		<div>
			<label htmlFor="countryList">Select Country:</label>
			<select value={selectedValue} onChange={handleInputChange}>
				{countryList.map(country => (
					<option value={country.id}>{country.name}</option>
				))}
			</select>
		</div>
	);
}

export default CountrySelect;