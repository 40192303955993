import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    setSubmitClicked(true);
    
    const headersList = {
      "Accept": "*/*",
    }

    const base64Credentials = btoa(`${email}:${password}`);

    headersList['Authorization'] = `Basic ${base64Credentials}`;

    const reqOptions = {
      url: "https://erp-api.55technology.com/v1/login/",
      method: "POST",
      headers: headersList,
      data: {
        auth: {
          email: email,
          password: password
        }
      }
    };

    try {
      const response = await axios.request(reqOptions);

      // Save the access token in local storage
      localStorage.setItem('access_token', response.data.access_token);
      console.log('Login successful:', response.data);

      console.log("Entrou");
      navigate("/company/select", { replace: true });

    } catch (error) {
        alert(error)
        setSubmitClicked(false);
        console.error('Error logging in:', error);
    }
  };

  return (
    <div className="bg-img">
      <form onSubmit={handleLogin} className="login-container">
        <h1>Login</h1>

        <label for="email"><b>Email</b></label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />

        <label for="psw"><b>Password</b></label>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />

        {
          submitClicked 
          ? <i className="fa-regular fa-sun rotating-star"></i> 
          : <button
              type="submit"
              className="btn"
              disabled={submitClicked}
            > Login </button>
        }

        <div className="column">
          <Link to='/pass/reset'>Esqueci minha senha</Link>
          <Link to='/user/create'>Cadastrar</Link>
          <Link to='/'>Voltar</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
