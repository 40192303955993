import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ClientInsert() {
	const [name, setName] = useState('');
	const [cpf, setCpf] = useState('');
	const [rg, setRg] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [gender, setGender] = useState(1);
	const [birthdate, setBirthdate] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [token, setToken] = useState('');

	const [submitClicked, setSubmitClicked] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);

	const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/client",
			method: "POST",
			headers: headersList,
			data: {
				"company_id": companyId,
				"name": name,
				"cpf": cpf,
				"rg": rg,
				"email": email,
				"phone": phone,
				"country_id": 1,
				"city_id": 88,
				"gender_id": gender,
				"profile_picture_url": null,
				"birthdate": birthdate
			}
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/client", { replace: true });
		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Novo cliente</h2>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>CPF:</label>
							<input 
								type="text" 
								value={cpf} 
								onChange={(e) => setCpf(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>RG:</label>
							<input 
								type="text" 
								value={rg} 
								onChange={(e) => setRg(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>E-mail:</label>
							<input 
								type="email" 
								value={email} 
								onChange={(e) => setEmail(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Telefone:</label>
							<input 
								type="text" 
								value={phone} 
								onChange={(e) => setPhone(e.target.value)} 
								required 
							/>
						</div>
						<div>
							<label>Gênero:</label>
							<select name="" id="" value={gender} onChange={(e) => setGender(e.target.value)} >
								<option value="1">Feminino</option>
								<option value="2">Masculino</option>
							</select>
						</div>
						<div>
							<label>Data de nascimento:</label>
							<input 
								type="date" 
								value={birthdate} 
								onChange={(e) => setBirthdate(e.target.value)} 
								required 
							/>
						</div>

						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar 
							</button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default ClientInsert
