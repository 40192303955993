import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function MenuList() {
  	return (
    	<Fragment>
			<Header/>
			<div id="main">
				<h2>Cardapios</h2>
			</div>
		</Fragment>
  	)
}

export default MenuList
