import React, { Fragment } from 'react'
import Header from '../../../layout/Header'

function Wallet() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Histórico financeiro</h2>
			</div>
		</Fragment>
	)
}

export default Wallet
