import React, {useState, useEffect} from 'react';
import axios from 'axios';

const GeoStateSelect = ({onSelect}) => {
	const [geoStateList, setGeoStateList] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(()=>{
		axios.get("https://erp-api.55technology.com/v1/state/br")
		.then (response => {
				console.table(response.data.states);
				setGeoStateList(response.data.states);
		})
		.catch (error => {
				console.error("Erro na API", error);
		});
	},[]);

	const handleInputChange = (event) => {
		setSelectedValue(event.target.value);
		onSelect(event.target.value);
	};

	return (
		<div>
			<label htmlFor="geoStateList">Estado:</label>
			<select value={selectedValue} onChange={handleInputChange}>
				{geoStateList.map(geoState => (
					<option value={geoState.id}>{geoState.name}</option>
				))}
			</select>
		</div>
	);
}
export default GeoStateSelect;