import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ProjectTagInsert() {
	const [companyId, setCompanyId] = useState('');
	const [name, setName] = useState('');
	const [colour, setColour] = useState('');

    const [token, setToken] = useState('');
	const [submitClicked, setSubmitClicked] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);

	const handleInsert = async (e) => {
		e.preventDefault();

		setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/project/tag",
			method: "POST",
			headers: headersList,
			data: {
				"company_id":companyId,
				"name": name,
				"colour": colour,
			}
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/project/tag", { replace: true });
		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Novo Imposto</h2>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>
						
						<div>
							<label>Cor :</label>
							<input 
								type="color" 
								value={colour} 
								onChange={(e) => setColour(e.target.value)} 
								required 
							/>
						</div>
						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar 
							</button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
}

export default ProjectTagInsert
