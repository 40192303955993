import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function CompanyCreate() {
  const [currentTab, setCurrentTab] = useState(0);
  const [registerClicked, setRegisterClicked] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyCnpj, setCompanyCnpj] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [isMei, setIsMei] = useState(false);

  const navigate = useNavigate();

  const nextPrev = (n) => {
    setCurrentTab(currentTab + n);
  };

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		var token = localStorage.getItem('access_token');
    setAccessToken(token)
  }, [accessToken, navigate])
  

  const handleInsert = async (e) => {
		e.preventDefault();

    // bloqueia o botão e renderiza a animação
    setRegisterClicked(true);

		const headersList = {
			"Accept": "*/*",
      "token": accessToken
		}

		const reqOptions = {
			// url: "http://erp-api.55technology.com/v1/company",
      url: "https://erp-api.55technology.com/v1/company",
			method: "POST",
			headers: headersList,
			data: {
        "name": companyName,
        "cnpj": companyCnpj,
        "plan_id": selectedPlan,
        "is_mei": isMei,
      }
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);

			// Navigate to /dash route
			navigate("/company/select");

		} catch (error) {
      setRegisterClicked(false);

			alert(error)
			console.error('Error:', error);
		}
	};

  // When the user scrolls the page, execute myFunction 
  window.onscroll = () => progressBarEffect();
  
  function progressBarEffect() {
    if (!registerClicked){
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      document.getElementById("myBar").style.width = scrolled + "%";
    }
  }

  return (
    <div>
      <form id="regForm" action="/action_page.php">
        <h1>Vamos cadastrar a sua empresa.</h1>

        <div className={`tab ${currentTab === 0 ? 'active' : 'hidden'}`}>
          <p>
            Nome da empresa:
            <input
              type='text'
              placeholder="Nome" 
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </p>
          <p>
            CNPJ:
            <input
              type='text'
              placeholder="(00) 0 0000-0000" 
              value={companyCnpj}
              onChange={(e) => setCompanyCnpj(e.target.value)}
              required
            />
          </p>
          <p>
            <h3>A empresa é MEI?</h3>
            <label className="switch">
              <input 
                type="checkbox"
                defaultChecked={isMei}
                onChange={() => setIsMei(!isMei)}
              />
              <span className="slider round"></span>
            </label>
          </p>          
        </div>

        <div className={`tab ${currentTab === 1 ? 'active' : ''}`}>
          <h3>Inicie gratuitamente!</h3>
          <p>Sem armadilhas e letrinhas miúdas, você poderá alterar ou cancelar seu plano a qualquer momento sem complicações.</p>

          <div className="row">
            <div className="plan-card" onClick={() => setSelectedPlan(1)} tabindex="0">
              <div className="plan-container">
                <h2>Anual</h2>
                <p className="plan-title"><s>119,90</s></p>
                <h1>R$79,99</h1>
                <sub>por mês no primeiro ano.</sub>
                <p><sub>Renovação a cada 12 meses.</sub></p>
                <p><sub>Primeiros 14 dias gratis!</sub></p>
              </div>
            </div>

            <div className="plan-card" onClick={() => setSelectedPlan(2)} tabindex="0">
              <div className="plan-container">
                <h2>Semestral</h2>
                <p className="plan-title"><s>119,90</s></p>
                <h1>R$99,99</h1>
                <p><sub>por mês no primeiro ano.</sub></p>
                <p><sub>Renovação a cada 6 meses.</sub></p>
                <p><sub>Primeiros 14 dias gratis!</sub></p>
              </div>
            </div>

            <div className="plan-card" onClick={() => setSelectedPlan(3)} tabindex="0">
              <div className="plan-container">
                <h2>Mensal</h2>
                <p className="plan-title"><s>119,90</s></p>
                <h1>R$119,99</h1>
                <p><sub>por mês no primeiro ano.</sub></p>
                <p><sub>Renovação a cada 30 dias.</sub></p>
                <p><sub>Primeiros 7 dias gratis!</sub></p>
              </div>
            </div>
          </div>
            {selectedPlan === 3
            ? <h3>Primeiro pagamento, apenas daqui a 7 dias</h3>
            : <h3>Primeiro pagamento, apenas daqui a 14 dias.</h3> }
        </div>

        {/* <div className={`tab ${currentTab === 2 ? 'active' : 'hidden'}`}>
          <h3>Como gostaria de pagar?</h3>
          <div className="row">
            <div className="plan-card" onClick={() => setSelectedPlan(1)} tabindex="0">
              <div className="plan-container">
                <h2>Pix</h2>
              </div>
            </div>

            <div className="plan-card" onClick={() => setSelectedPlan(1)} tabindex="0">
              <div className="plan-container">
                <h2>Crédito</h2>
              </div>
            </div>

            <div className="plan-card" onClick={() => setSelectedPlan(1)} tabindex="0">
              <div className="plan-container">
                <h2>Boleto</h2>
              </div>
            </div>
          </div>
        </div> */}

        <div className={`tab ${currentTab === 2 ? 'active' : 'hidden'}`}>
         
         <h2>Termos de uso e privacidade</h2>
          <div className="scroll-header">
            <div className="progress-container">
              <div className="progress-bar" id="myBar"></div>
            </div>  
          </div>

          <div className="content">
            <h3>Lalalalalalala</h3>
            <p>Lalalala</p>
            <p>Oioi</p>
            <p>Lalalala</p>
            <h3>Termos de uso e privacidade</h3>
            <p>It also <b>works when you scroll back up</b>.</p>
            <p>It is even <b>responsive</b>! Resize the browser window to see the effect.</p>
            <p>Some text to enable scrolling.. <b> Lorem ipsum dolor sit amet, </b> illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
            <p>Some text to enable scrolling.. Lorem ipsum dolor sit amet, illum definitiones no quo, maluisset concludaturque et eum, altera fabulas ut quo. Atqui causae gloriatur ius te, id agam omnis evertitur eum. Affert laboramus repudiandae nec et. Inciderint efficiantur his ad. Eum no molestiae voluptatibus.</p>
          </div>

          <p>
            <h3>Declaro que li e estou de acordo com os temos de uso e privacidade apresentados:</h3>
            <label className="switch">
              <input type="checkbox"/>
              <span className="slider round"></span>
            </label>
          </p>   
        </div>

        {/* <div className={`tab ${currentTab === 4 ? 'active' : 'hidden'}`}>
          <h3>Estamos quase lá...</h3>
          <p>Se for crédito, mostra o form pra cadastrar o cartão.</p>
          <p>Se for pix, mostra o qrcode e o código</p>
          <p>Se for boleto, pede para confirmar o email e cpf antes de mandar.</p>
        </div> */}
        
        <div className='button-group' style={{ overflow: 'auto' }}>
          {currentTab < 2 ? (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                id="prevBtn"
                onClick={() => nextPrev(-1)}
                disabled={currentTab === 0}
              >
                Voltar
              </button>

              <button type="button" id="nextBtn" onClick={() => nextPrev(1)}>
                Continuar
              </button>
            </div>
          ) : (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                id="prevBtn"
                onClick={() => nextPrev(-1)}
                disabled={currentTab === 0}
                > Voltar </button>

              {
                registerClicked 
                ? <i className="fa-regular fa-sun rotating-star"></i> 
                : <button
                    type="button"
                    id="registerBtn"
                    onClick={handleInsert}
                    disabled={registerClicked}
                  > Registrar </button>
              }
            </div>
          )}
        </div>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          {[...Array(3)].map((_, index) => (
            <span key={index} className={`step ${currentTab === index ? 'active' : ''}`}></span>
          ))}
        </div>
      </form>
    </div>
  );
}

export default CompanyCreate
