import React, { Fragment, useEffect, useState } from 'react'

import Header from '../../../layout/Header'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CitySelect from '../../../selects/CitySelect';

const ProductCategoryInsert = () => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [cnpj, setCnpj] = useState('');

	const [selectedCity, setSelectedCity] = useState('');
	
	const [submitClicked, setSubmitClicked] = useState(false);
	const [companyId, setCompanyId] = useState('');
	const [token, setToken] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate("/login");
		}

		if (!localStorage.getItem('selected_company')) {
			navigate("/company/select");
		}

		var accessToken = localStorage.getItem('access_token');
		setToken(accessToken);

		var company_id = localStorage.getItem('selected_company');
		setCompanyId(company_id);

	}, [navigate]);

	const handleCitySelect = (value) => {
		setSelectedCity(value);
	};

	const handleInsert = async (e) => {
		e.preventDefault();
        setSubmitClicked(true);

		const headersList = {
			"Accept": "*/*",
			"token": token
		}

		const reqOptions = {
			url: "https://erp-api.55technology.com/v1/supplier",
			method: "POST",
			headers: headersList,
			data: {
				"company_id": companyId,
				"name": name,
				"phone": phone,
				"email": email,
				"city_id": selectedCity,
				"cnpj": cnpj,
			}
		};

		try {
			const response = await axios.request(reqOptions);
			console.log('create successful:', response.data);
			setSubmitClicked(false);
			navigate("/supplier", { replace: true });
		} catch (error) {
			setSubmitClicked(false);
			alert(error)
			console.error('Error:', error);
		}
	};

  	return (
		<Fragment>
			<Header/>
			<div id="main">
				<section className="column">
					<form onSubmit={handleInsert}>
						<h2>Novo fornecedor</h2>
						<div>
							<label>Nome:</label>
							<input 
								type="text" 
								value={name} 
								onChange={(e) => setName(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>Telefone:</label>
							<input 
								type="text" 
								value={phone} 
								onChange={(e) => setPhone(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>Email:</label>
							<input 
								type="text" 
								value={email} 
								onChange={(e) => setEmail(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<label>CNPJ:</label>
							<input 
								type="text" 
								value={cnpj} 
								onChange={(e) => setCnpj(e.target.value)} 
								required 
							/>
						</div>

						<div>
							<CitySelect onSelect={handleCitySelect}/>
						</div>

						{
							submitClicked 
							? <i className="fa-regular fa-sun rotating-star"></i> 
							: <button
								type="submit"
								className="btn"
								disabled={submitClicked}
								> Cadastrar </button>
						}
					</form>
				</section>
			</div>
		</Fragment>
  	)
};

export default ProductCategoryInsert;
