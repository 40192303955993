import React, { Fragment } from 'react'

import Header from '../../../layout/Header'

function WikiDetail() {
  return (
	<Fragment>
		<Header/>
		<div id="main">
			<h2>Detalhes de uma wiki</h2>
		</div>
	</Fragment>

  )
}

export default WikiDetail
