import React, { Fragment } from 'react'
import Header from '../../layout/Header'

function Notification() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Notificações</h2>
			</div>
		</Fragment>
	)
}

export default Notification
