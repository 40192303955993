import React, {useState, useEffect} from 'react';
import axios from 'axios';

const CitySelect = ({onSelect}) => {
	const [cityList, setCityList] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(()=>{
		axios.get("https://erp-api.55technology.com/v1/city/state/br/15")
		.then (response => {
				console.table(response.data.city);
				setCityList(response.data.city);
		})
		.catch (error => {
				console.error("Erro na API", error);
		});
	},[]);

	const handleInputChange = (event) => {
		setSelectedValue(event.target.value);
		onSelect(event.target.value);
	};

	return (
		<div>
			<label htmlFor="cityList">Cidade:</label>
			<select onChange={handleInputChange} value={selectedValue}>
				{cityList.map(city => (
					<option value={city.id} selected> {city.name}</option>
				))}
			</select>
		</div>
	);
}
export default CitySelect;