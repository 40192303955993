import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ClientSelect = ({onSelect}) => {
		const [clientList, setClientList] = useState([]);
		const [selectedValue, setSelectedValue] = useState('');

		const navigate = useNavigate();

		useEffect(()=>{

			if (!localStorage.getItem('access_token')) {
				navigate("/login");
			}
	
			if (!localStorage.getItem('selected_company')) {
				navigate("/company/select");
			}
	
			var accessToken = localStorage.getItem('access_token');
			var company_id = localStorage.getItem('selected_company');
	
			axios.get(`https://erp-api.55technology.com/v1/client/company/` + company_id, {
				headers: { "token": accessToken }
			})
				.then (response => {
					console.table(response.data.clients);
					setClientList(response.data.clients);
				})
				.catch (error => {
					console.error("Erro na API", error);
				});
		},[navigate]);

		const handleInputChange = (event) => {
			setSelectedValue(event.target.value);
			onSelect(event.target.value);
		};

		return (
			<div>
				<label htmlFor="clientList">Cliente:</label>

				<select onChange={handleInputChange} value={selectedValue}>
					{clientList.map(client => (
						<option value={client.id}>{client.client_name}</option>
					))}
				</select>
			</div>
		);
}
export default ClientSelect;