import React, { Fragment } from 'react';
import Header from '../../../layout/Header';

function FoodTechList() {
	return (
		<Fragment>
			<Header/>
			<div id="main">
				<h2>Ficha técnica</h2>
			</div>
		</Fragment>
	)
}

export default FoodTechList
